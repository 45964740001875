import React from 'react';
import styled from 'styled-components';


import { IWidgetData } from '../data';

import GenDialog from '../GenDialog';
import { Loader, IDropdownOption, ToPrintButton, PrintedHeader, PrintedTitle, PrintedFormData, PrintNatalProlog, IGenInfo } from 'src/libs';
import { devplan } from 'src/helpers/api';
import { toDateTimeString } from 'src/api';
import { t } from 'i18next';

function listItems(items: string[]) {
  return items.map((item, i) => <li key={`li_${i}`} dangerouslySetInnerHTML={{__html: item}} />);
}

function tenseAspectsHeader(data: any, color: string, className: string) {
  return (
    <div className={`aspect-item ${className}`}>
      <img
        width="20px"
        height="20px"
        src={data.img}
        style={{
          position: 'relative'
        }}
      />
      <h1 style={{
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '16px',
        color,
        position: 'relative',
        left: '5px',
        top: '-4px'
      }}>{data.title}</h1>
      <p>{data.text}</p>
    </div>
  );
}

function tenseAspects(items: any[]) {
  return items.map((item, i) =>
    <div key={`item_${i}`}>
      {tenseAspectsHeader(item.negative, '#D72222', 'minus')}
      {item.prescription ? tenseAspectsHeader(item.prescription, '#F2994A', 'aspect-item birth') : ''}
      {tenseAspectsHeader(item.compensation, '#3281CA', 'equal')}
      {tenseAspectsHeader(item.positive, '#429F22', 'compensation')}
      <div className="tense-sep" />
    </div>
  );
}

function disclaimer(v: any) {
  return <p className="disclaimer">{v.text} <a href={`mailto:${v.email}`}>{v.email}</a>.</p>;
}

function lifehack(text: string) {
  return <p style={{
    paddingLeft: '20px',
    borderLeft: '3px solid currentColor'
  }}><b>{t("chronos.app.compensatoryLifeHack")} </b>{text}</p>;
}

function signal(text: string) {
  return <p className="signal">{text}</p>;
}

function group(group: string[]) {
  return <ul>{group.map((p, i) => <li key={`g_${i}`}>{p}</li>)}</ul>;
}

function result(v: { text: string; marker?: string; }, id: number) {
  const toks = v.text.split('#!');
  return (
    <React.Fragment key={`result_${id}`}>
      <li>
        <div className="block">
          {v.marker && <div className="marker">{v.marker} </div>}
          <div className="text">{toks[0]}</div>
        </div>
      </li>
      {toks.length == 2 && <span className="red-cursive">{toks[1]}</span>}
    </React.Fragment>
  );
}

function birth(data: any) {
  return <Section>
    <h3>{data.instruction.label}</h3>
    {data.instruction.items.map((i: any, id: number) => <p key={`instr_${id}`}>{i}</p>)}

    <ul>
      {data
        .contents
        .map((i: any, id: number) => <li key={`cont_${id}`}><b>{i.label}</b> {i.text}</li>)
      }
    </ul>

    {data.resource.map((i: any, id: number) => <p key={`res_${id}`}>{i}</p>)}

    <h2>{data.birth[0].label}</h2>

    <p>{data.birth[0].auxLabel}</p>

    <ul style={{ color: '#282' }}>{listItems(data.birth[0].aux)}</ul>

    <p>{data.birth[0].baggagesLabel}</p>

    <ul style={{ color: '#282' }}>{listItems(data.birth[0].baggages)}</ul>

    {data.birth[0].next.map((p: string, i: number) => <p key={i.toString()}>{p}</p>)}

    <ul style={{ color: '#282' }}>{listItems(data.birth[0].partners)}</ul>

    {data.birth[0].warnings.map((w: string, i: number) => <p key={`w_${i}`} style={{ color: 'var(--color-red)', fontWeight: 'bold' }}>!!! {w}</p>)}

    <h2>{data.birth[1].label}</h2>

    <p>{data.birth[1].description1}</p>

    {data.birth[1].moon.length > 0 &&
      <>
        <p>{data.birth[1].description2}</p>

        <ul style={{ color: '#282' }}>{listItems(data.birth[1].moon)}</ul>

        <p>{data.birth[1].comment1}</p>
      </>
    }

    {data.birth[1].moonTenseAspects.length > 0 &&
      <>
        {tenseAspects(data.birth[1].moonTenseAspects)}

        <p>{data.birth[1].comment2}</p>
      </>
    }

    {lifehack(data.birth[1].lifehack)}

    <p><b>{data.birth[1].problems.label}</b></p>

    <ul>{data.birth[1].problems.items.map((i: any, id: number) => <li key={`prob1_${id}`}>{i}</li>)}</ul>

    {signal(data.birth[1].signal)}

    <h2>{data.birth[2].label}</h2>

    <p>{data.birth[2].description1}</p>

    {data.birth[2].sun.length > 0 &&
      <>
        <p>{data.birth[2].description2}</p>

        <ul style={{ color: '#282' }}>{listItems(data.birth[2].sun)}</ul>

        <p>{data.birth[2].comment1}</p>
      </>
    }

    {data.birth[2].sunTenseAspects.length > 0 &&
      <>
        {tenseAspects(data.birth[2].sunTenseAspects)}

        <p>{data.birth[2].comment2}</p>
      </>
    }

    {lifehack(data.birth[2].lifehack)}

    <p><b>{data.birth[2].problems.label}</b></p>

    <ul>{data.birth[2].problems.items.map((i: any, id: number) => <li key={`prob2_${id}`} >{i}</li>)}</ul>

    {signal(data.birth[2].signal)}

    <h2>{data.birth[3].label}</h2>

    <p>{data.birth[3].description1}</p>

    <p>{data.birth[3].description2}</p>

    <p>{data.birth[3].description3}</p>

    <ul className="gray-list">{listItems(data.birth[3].habits)}</ul>

    <p>{data.birth[3].saturnProblemsLabel}</p>

    <ul className="gray-list">{listItems(data.birth[3].saturnProblems)}</ul>

    <p>{data.birth[3].jupiterLabel}</p>

    <ul className="gray-list">{listItems(data.birth[3].jupiter)}</ul>

    <p><b>{data.birth[3].problems.label}</b></p>

    <ul>{data.birth[3].problems.items.map((i: any, id: number) => <li key={`prob3_${id}`}>{i}</li>)}</ul>

    {signal(data.birth[3].signal)}

    <h2>{data.birth[4].label}</h2>

    <p>{data.birth[4].description1}</p>

    <p>{data.birth[4].description2}</p>

    <p>{data.birth[4].tasksLabel}</p>

    <ul className="gray-list">{listItems(data.birth[4].tasks)}</ul>

    <p>{data.birth[4].retributionLabel}</p>

    <ul className="gray-list">{listItems(data.birth[4].retribution)}</ul>

    {disclaimer(data.disclaimer)}
  </Section>;
}

function energy(data: any) {
  return <Section>
    <h2>{data.question}</h2>

    <p>{data.potential}</p>

    <p>{data.firstSource}</p>

    <ul className="gray-list">
      {listItems(data.energySigns)}
    </ul>

    <p>{data.secondSource}</p>

    <p>{data.warning}</p>

    <ul className="gray-list">
      {listItems(data.marsHouses)}
    </ul>

    {data.marsTenseAspects.length > 0 &&
      <>
        <p>{data.marsTenseAspectsLabel}</p>
        {tenseAspects(data.marsTenseAspects)}
      </>
    }

    {disclaimer(data.disclaimer)}
  </Section>;
}

function health(data: any) {
  return <Section>
    <p style={{ color: 'green' }}>{data.warning}</p>

    <p>{data.warning2}</p>

    {data.group1.length > 0 && <>
      <p>{data.group1Label}</p>

      <div style={{
        color: 'red',
        display: 'flex',
        flexDirection: 'column'
      }}>
        {data.group1Aux.map((a: string, i: number) => <div key={`aux_${i}`}>{a}</div>)}
      </div>

      {group(data.group1)}
    </>}

    {data.group2.length > 0 && <>
      <p>{data.group2Label}</p>
      {group(data.group2)}
    </>}

    {data.group1.length > 0 && data.group2.length > 0 && <p>{data.warning3}</p>}
    {data.group1.length === 0 && data.group2.length ===  0 ?
      <p>{t("chronos.app.devPlan.noRiskMessage")}</p>:
      <div>
        {data.probabilities.map((p: string, i: number) => <p
        key={`prob_${i}`}
        style={{
          fontWeight: 'bold',
          color: '#D00000'
        }}
        >{p}</p>)}
      </div>
    }
  </Section>;
}

function proforientation(data: any) {
  return <Section>
    <i>{data.warning}</i>

    <p>{data.talentsMapLabel}</p>

    <ul>{
      data.talentsMap.map((t: any, i: number) => <li key={`talent_${i}`} dangerouslySetInnerHTML={{__html: ` +${t.v} ${t.k}`}}></li>)}

    </ul>

    <p>{data.priorityDirections}</p>

    <ul>{data.niches.map((n: any, i: number) => <li key={i.toString()} dangerouslySetInnerHTML={{__html: n.k}}></li>)}</ul>
  </Section>;
}

function finance(data: any) {
  return <Section>
    {data.recommendation}

    <br />
    <br />

    <ol style={{ paddingLeft: '2em' }}>
      {data.instruction.map((t: string, i: number) => {

      return <li key={`instr_${i}`} dangerouslySetInnerHTML={{__html: t}} />})}
    </ol>

    <br />
    <hr />
    <br />

    {data.label1}

    <br />
    <br />

    {data.commonMoneyMakersLabel}

    <ol style={{ paddingLeft: '3em' }}>
      <li style={{ marginTop: '1em' }}>
        <span style={{ fontWeight: 'bold', color: '#373' }}>{data.yinYang.who}&nbsp;</span>
        {data.yinYang.lines.map((i: string, id: number) => <React.Fragment key={`yy_${id}`}>{i}<br /></React.Fragment>)}
        {data.yinYang.warning && <span className="red-cursive">{data.yinYang.warning}</span>}
      </li>
      {data.commonMoneyMakers.map(result)}
    </ol>

    <br />

    {data.specificMoneyMakersLabel}

    <br />

    <ol style={{ paddingLeft: '3em' }}>
      {data.specificMoneyMakers.map(result)}
    </ol>

    <br />
    <hr />
    <br />

    {data.label2}

    {data.realization.map((r: any, id: number) =>
      <React.Fragment key={`real_${id}`}>
        <div style={{ marginTop: '2em' }}>{r.label}</div>
        <span style={{ color: r.color }} dangerouslySetInnerHTML={{__html: r.text}} />
      </React.Fragment>
    )}

    <br />
    <br />

    <hr />

    <br />

    {data.label3}

    {/* <ul>{listItems(data.top7)}</ul> */}
    <ul>
      {(data.top7 as any[]).map((t, i) =>
        <li key={`top7_${i}`}>
          <div className="block">
            {t.marker && <div className="marker">{t.marker}</div>}
            <div className="text">{t.text}</div>
          </div>
        </li>
      )}
    </ul>
  </Section>;
}

export default function DevPlan(props: {
  data: IWidgetData;
  onChanged(key: string, value: any): void;
  page: IDropdownOption,
  opened: boolean;
}) {
  const page = props.page;
  const [info, setInfo] = React.useState<IGenInfo | null>(null);
  const [data, setData] = React.useState<{
    birth: string;
    energy: string;
    proforientation: string;
    health: string;
    finance: string;
  } | null>(null);

  const onBuild = async (confirmed = true) => {
    const resp = await devplan(confirmed, props.data.form);

    props.onChanged('devplan', resp.done);

    if (resp.done) {
      setInfo(null);
      setData(resp.data);
    } else {
      // Force update
      setInfo({
        ...resp,
        timestamp: Date.now()
      });
      setData(null);
    }
  };

  React.useEffect(() => {
    if(!props.opened) return;
    onBuild(false);
  }, [props.opened]);

  if (info) {
    return (
      <GenDialog
        title={t("chronos.app.devPlan")}
        info={info}
        onBuild={onBuild}
      />
    );
  }

  if (!data) { return <LoaderContainer><Loader /></LoaderContainer> }

  return (
    <>
      <Content>
        <div className='section-controls'>
          <StyledToPDFButton
            className='to-print'
            elements={['#idp-print-header', '#print-map', '#idp-print-content']}
            inseparable={['.print-inseparable', '.local-trend']}
            excludes={['.natal-container']}
          >
            <span>{t("chronos.app.downloadInPdf")}</span>
          </StyledToPDFButton>
      </div>

        <div id="idp-print-header">
          <PrintedHeader label={t("chronos.app.devPlan")} />
          <PrintedTitle>
            {t(props.page.label!)}
          </PrintedTitle>
          <PrintedFormData>
            <span>{props.data.form.name}</span>,&nbsp;
            <span>{toDateTimeString(props.data.form.natal.dt)}</span>,&nbsp;
            <span>{props.data.form.natal.place.name}</span>
          </PrintedFormData>
        </div>

        <div id="idp-print-content">
          <PrintNatalProlog text={t("chronos.app.downloadInPdf.printInfo")} />

          {!page || page?.value === '0' && birth(data.birth)}
          {page?.value === '1' && energy(data.energy)}
          {page?.value === '2' && proforientation(data.proforientation)}
          {page?.value === '3' && health(data.health)}
          {page?.value === '4' && finance(data.finance)}
        </div>
      </Content>
    </>
  );
};


export const Content = styled.div`
  width: 100%;
`;

export const Section = styled.div`
  position: relative;
  margin-top: 1.5rem;
  width: 100%;

  h2 {
    margin: 0;
    margin-bottom: 0.313em;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.125;
    color: var(--text-primary);

    :not(:first-child) {
      margin-top: 0.875em;
    }
  }

  h3 {
    margin: 0;
    margin-bottom: 0.313em;
  }

  h5 {
    margin-bottom: 1.5em;
    font-size: 0.75rem;
    font-weight: 600;
    color: var(--text-third);
  }

  ul {
    margin-top: 1em;
    margin-bottom: 1em;
    padding-left: 2em;
    color: var(--text-secondary);

    li:not(:last-child) {
      margin-bottom: 1em;
    }

    li {
      b {
        color: var(--text-primary);
      }
    }
  }

  p {
    margin-bottom: 1.215em;
    color: var(--text-secondary);
  }

  .list-item {
    color: var(--text-secondary);
  }

  .list-item_bold {
    font-weight: bold;
  }

  .list-item_black {
    color: var(--text-primary);
  }

  .block,
  .list-item-data .block {
    display: flex;
    flex-direction: row;
    margin-bottom: 1.313rem;
  }

  .block .text {
    /* margin-inline-start: 1rem; */
  }

  .block .marker,
  .list-item-data .marker {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 1.75rem;
    height: 1.5rem;
    border-radius: 0.313rem;
    color: #ffffff;

    font-size: 0.75rem;
    font-weight: 600;
  }

  .list-item-data .text {
    margin-left: 0.875rem;
    color: var(--text-secondary);
  }

  .list-item-data.compensation .marker {
    background-color: var(--green-color) !important;
  }

  .list-item-data.compensation .intro {
    color: var(--green-color);
    font-weight: 600;
  }

  .list-item.red {
    border-color: var(--red-color);
  }

  .list-item.red .marker {
    background-color: var(--red-color);
  }

  .list-item.gray {
    border-color: var(--text-third);
  }

  .list-item.gray .marker {
    background-color: var(--text-third);
  }

  .disclaimer {
    font-style: italic;
  }

  .red-cursive {
    font-style: italic;
    color: #D00000;
  }

  .signal {
    font-weight: bold;
    color: red;
  }

  .tense-sep {
    margin: 1.75em 0;
    border-top: 1px solid var(--element);
  }

  .aspect-item {
    display: block !important;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 1.5rem;
    padding: 1.25rem 1.5rem;
    border-radius: 0.375rem;
    border: 1px solid;

    @media print {
      border-width: 1pt !important;
    }
  }

  .aspect-item.minus {
    background-color: rgba(239, 83, 80, 0.1);
    border-color: rgba(239, 83, 80, 0.5);
  }
  .aspect-item.birth {
    background: rgba(242, 153, 74, 0.1);
    border: 1px solid rgba(242, 153, 74, 0.5);
    @media print {
      border-width: 1pt;
    }
  }
  .aspect-item.equal {
    background-color: rgba(64, 147, 244, 0.1);
    border-color: rgba(64, 147, 244, 0.5);
  }

  .aspect-item.compensation {
    background-color: rgba(115, 178, 48, 0.1);
    border-color: rgba(115, 178, 48, 0.5);
  }

  .aspect-item h1 {
    font-size: 1.125rem;
    line-height: 1;
    margin: 0.625rem 0;
  }

  .aspect-item svg,
  .aspect-item img {
    width: 1.5rem;
    height: 1.5rem;

    @media print {
      margin-bottom: 1rem;
    }
  }

  .aspect-item p {
    margin: 1rem 0;
  }
`;

const LoaderContainer = styled.div`
  height: calc(100vh - 10rem);
`;

const StyledToPDFButton = styled(ToPrintButton)`
  display: flex;
  align-items: center;
  padding: 0;
  color: var(--accent-blue);
  background: transparent;
`;

const StyledToPDFButtonForIos = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
  color: var(--accent-blue);
  background: transparent;
`;
