
import { Dispatch } from 'redux';
import { PrognosticsMode, SynastryMode } from 'src/libs';
import { ECompatibilityTransitMode, TCompatibilityKey, EInitSynastryTransitsCommand } from './types';
import { map } from 'lodash';

export const MAPINDICATOR_SET_COMPATIBILITY_REVERSED = 'WORKSPACE_SET_COMPATIBILITY_REVERSED';
export const MAPINDICATOR_SET_COMPATIBILITY_TRANSIT_MODE = 'MAPINDICATOR_SET_COMPATIBILITY_TRANSIT_MODE';
export const MAPINDICATOR_SET_COMPATIBILITY_TRANSIT_SHOW = 'MAPINDICATOR_SET_COMPATIBILITY_TRANSIT_SHOW';
export const MAPINDICATOR_SET_SYNASTRY_TRANSITS_INIT = 'MAPINDICATOR_SET_SYNASTRY_TRANSITS_INIT';
export const MAPINDICATOR_SET_SYNASTRY_TRANSITS_RESET = 'MAPINDICATOR_SET_SYNASTRY_TRANSITS_RESET';

export const MAPINDICATOR_SET_PROGNOSTICS_EXT = 'MAPINDICATOR_SET_PROGNOSTICS_EXT';
export const MAPINDICATOR_SET_PROGNOSTICS_NATAL = 'MAPINDICATOR_SET_PROGNOSTICS_NATAL';

export const MAPINDICATOR_SET_PARTNER_EXT = 'MAPINDICATOR_SET_PARTNER_EXT';
export const MAPINDICATOR_SET_PARTNER_NATAL = 'MAPINDICATOR_SET_PARTNER_NATAL';

export function setMapCompatibilityReversed (value: boolean) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: MAPINDICATOR_SET_COMPATIBILITY_REVERSED,
            payload: value
        });
    }
}

export function setMapIndicatorPartnerNatal(value: boolean, mapMode: SynastryMode) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: MAPINDICATOR_SET_PARTNER_NATAL,
            payload: {
                mapMode,
                value
            }
        });
    }
}

export function setMapIndicatorPartnerExt(value: boolean, mapMode: SynastryMode) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: MAPINDICATOR_SET_PARTNER_EXT,
            payload: {
                mapMode,
                value
            }
        });
    }
}

export function setMapIndicatorPrognosticsNatal(value: boolean, mapMode: PrognosticsMode) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: MAPINDICATOR_SET_PROGNOSTICS_NATAL,
            payload: {
                mapMode,
                value
            }
        });
    }
}

export function setMapIndicatorPrognosticsExt(value: boolean, mapMode: PrognosticsMode) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: MAPINDICATOR_SET_PROGNOSTICS_EXT,
            payload: {
                mapMode,
                value
            }
        });
    }
}

export function setCompatibilityTransitMode(value: ECompatibilityTransitMode, mapMode: TCompatibilityKey) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: MAPINDICATOR_SET_COMPATIBILITY_TRANSIT_MODE,
            payload: {
                mapMode,
                value
            }
        });
    }
}

export function setCompatibilityTransitShow(value: boolean, mapMode: TCompatibilityKey) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: MAPINDICATOR_SET_COMPATIBILITY_TRANSIT_SHOW,
            payload: {
                mapMode,
                value
            }
        });
    }
}

export function setInitSynastryTransits(value: EInitSynastryTransitsCommand) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: MAPINDICATOR_SET_SYNASTRY_TRANSITS_INIT,
            payload: {
                value
            }
        });
    }
}

export function resetSynastryTransits() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: MAPINDICATOR_SET_SYNASTRY_TRANSITS_RESET,
            payload: {}
        });
    }
}