import { DateTimeInput, GMTSelect, Input, InputContainer, PlaceInput, Radio, Switcher, Gender, IFormData, IPlace, ISynastryPartnerData, EditFormType } from 'src/libs';
import { cloneDeep } from 'lodash';
import React, { useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import api, { HousesSystem } from 'src/api';
import StarCatalog from 'src/app/pages/settings/StarCatalog';
import StarData from 'src/app/pages/settings/components/stars.json';
import { RelocationIcon, SoulIcon } from 'src/assets/icons/maps';
import HorarIcon from 'src/assets/icons/maps/horar.svg';
import NatalIcon from 'src/assets/icons/maps/natal.svg';
import PrognosticsIcon from 'src/assets/icons/maps/prognostics.svg';
import SynastryIcon from 'src/assets/icons/maps/synastry.svg';
import { ChevronRightIcon, StarIcon } from 'src/assets/icons/system';
import { useSwitcherHook } from 'src/hooks';
import i18n from 'src/i18n/i18n';
import { useTranslation } from 'src/i18n/useTranslation';
import { getSettings, getSettingsAstro, updateAstroSettings, updateSettings } from 'src/store/actions/settings';
import { getProfile } from 'src/store/localStorage/profile';
import { allSettings, getActiveAstroProfile, allSettingsAstro } from 'src/store/reducers/settings/selectors';
import { PopupBottom } from 'src/ui/Popups/PopupBottom';
import styled, { css } from 'styled-components';
import { IFormError } from './index';
import { ExpandedSection } from './ExpandedSection';
import { HousesSystemSelect } from './HousesSystemSelect';

export type TEditFormType = EditFormType | 'syn_prognostics';

export const infos = {
  natal: {
    title: "astro.natal",
    icon: <NatalIcon/>,
    label: "chronos.app.components.editForm.mainGroup.birth"
  },
  synastry: {
    title: "astro.synastry",
    icon: <SynastryIcon/>,
    label: "chronos.app.components.editForm.mainGroup.partnerBirth"
  },
  prognostics: {
    title: "astro.prognostics",
    icon: <PrognosticsIcon/>,
    label: "chronos.app.components.editForm.mainGroup.prognosis"
  },
  syn_prognostics: {
    title: "astro.prognostics",
    icon: <PrognosticsIcon/>,
    label: "chronos.app.components.editForm.mainGroup.prognosis"
  },
  horar: {
    title: "astro.horar",
    icon: <HorarIcon/>,
    label: "chronos.app.components.editForm.mainGroup.question"
  },
  soul: {
    title: "astro.formulaSoul",
    icon: <SoulIcon/>,
    label: "chronos.app.components.editForm.mainGroup.birth"
  },
  relocation: {
    title: "chronos.app.settings.relocation", // RU
    icon: <RelocationIcon/>,
    label: "astro.relocation.genitive"
  }
};

export default function MainGroup(props: {
	form: IFormData;
  type: TEditFormType;
  place: IPlace | null;
  date: string;
  time: string;
  gmt: number;
  cosmogram: boolean;
  mode: string;
  gender?: Gender;
  question?: string;
  natal?: ISynastryPartnerData;
  showAspects?: boolean;
  fixedStars?: { [key: string]: any };
  setPlace(v: IPlace): void;
  setDate(v: string): void;
  setTime(v: string): void;
  setGMT(v: number): void;
  setCosmogram(v: boolean): void;
  setGender(v: Gender): void;
  setQuestion?(v: string): void;
  setHigherPlanets?(v: boolean): void;
  setShowAspects?(v: boolean): void;
  setFixedStars?(v: { [key: string]: any }): void;
  disabled: boolean;
  errors: IFormError[];
  partnerFormOptions?: any[];
  selectedFormId?: number | null;
  setSelectedFormId?(v: any): void;
  name?: string;
  setName?(v: string): void;
	popupRef?: React.RefObject<HTMLDivElement>
}) {
	const { data: settings } = useSelector(allSettings);
	const activeAstroProfile = useSelector(getActiveAstroProfile);
  const { data: astroProfiles } = useSelector(allSettingsAstro);

	const [housesSystem, setHousesSystem] = React.useState(props.type === 'horar' ? props.form.housesSystem.horar : HousesSystem.Placidus);
	const [showHigherPlanets, setShowHigherPlanets] = React.useState(activeAstroProfile.showHigherPlanets);
  const [showObjectCatalog, setShowObjectCatalog] = React.useState<boolean>(false);

  const inputRef = useRef<HTMLDivElement | null>(null)

  const sections = settings.profile?.ui.instruments[props.type === 'syn_prognostics' ? 'prognostics' : props.type];
	const additionalIsOpened = useSwitcherHook((sections as any)?.additional ?? true);

  const dispatch = useDispatch();

	const options = useMemo(() => {
		return (
      props.partnerFormOptions?.map(option => ({
				...option,
				action: (opt: any) => props.setSelectedFormId?.(opt.value),
			})) || []
		)
	}, [props]);


  const onGender = (v: string) => props.setGender(v as Gender);
  const { t } = useTranslation();

  const onDate = (d: string) => {
    props.setDate(d);
  };

  const onTime = (t: string) => {
    props.setTime(t);
  }

  const onPlace = (p: IPlace | null) => {
    if (!p) return;
    props.setPlace(p);
  };

  const onShowStarCatalog = () => {
    setShowObjectCatalog(true);
  }

  const gender = props.gender || null;

  const info = infos[props.type];

  const errors = props.errors.filter(item => item.node === props.type)

  const changeHigherPlanets = async (v: boolean) => {
    setShowHigherPlanets(v)
    await updateAstroProfile({ showHigherPlanets: v }, Boolean('saveToBackend'));
  }

  const onFixedStars = React.useCallback(async (mode: string, list: string[], showWithObjects: boolean) => {
    props.setFixedStars!({
      ...props.fixedStars,
      [mode]: {
        list,
        showWithObjects
      }
    })
  }, [props.fixedStars])
  
  const errorForField = (field: string) => {
    return errors.find(item => item.field === field)?.text || ''
  };

  const natalPlaceMessage = React.useMemo((): string | undefined => {
    const { place, natal } = props;
    return natal?.place.name && (place?.name === natal?.place.name) ? t("chronos.app.mainGroup.filledNatalCity") : undefined;
  }, [props.place, props.natal]);

  const updateAstroProfile = async (newValue: { [key: string]: any }, saveToBackend: boolean) => {
    let profile = cloneDeep(activeAstroProfile!);

    const userProfile = astroProfiles.find((p: any) => p.mutable)!; // TODO // по mutable=true находим Мой профиль (возможно измениться в будущем)

    profile = {
      ...profile,
      ...newValue
    };

    if (saveToBackend) {
      profile.mutable = true
    }

    if (profile.id !== userProfile.id) {
      profile.id = userProfile.id;
      profile.title = userProfile.title;
      profile.mutable = true;
    }

    dispatch(updateAstroSettings(profile, (data) => {
      dispatch(updateSettings({ activeProfileId: data.id, activeCustomizationId: data.idCustomization }));
    }));
  }

	const sectionsChanged = (key: string, value: boolean) => {
    if (props.type === 'relocation') return;

		//FIXME: Find a alternative function
    // settings.profile.updateUi((ui: any) => {
    //   (ui.instruments.cardSettingsForms[props.type] as any)[key] = value;
    // });
  };

  return (
    <Container disabled={props.disabled} className={`${props.type}-group`} ref={inputRef}>
      {props.type === 'natal' &&
          <Label>
            <Title>{t("chronos.app.components.editForm.baseData")}</Title>
          </Label>
      }

      {props.type === 'horar' &&
        <StyledInputContainer label={t("base.question")} message={errorForField('question')}>
          <Input
            size='big'
            value={props.question || ''}
            onChange={props.setQuestion || (() => {})}
            placeholder={t("base.questionInfo")}
            mode={errorForField('question') ? 'error' : 'normal'}
          />
        </StyledInputContainer>
      }

      { props.type === 'synastry' && 
        <StyledInputContainer label={t('Карта партнера')}>
          <Input
            size="medium"
            options={options}
            value={props.selectedFormId}
          />
        </StyledInputContainer>
      }

      {props.type === 'synastry' &&
        <StyledInputContainer label={t("base.name")}>
          <Input
            value={props.name}
            onChange={props.setName}
          />
        </StyledInputContainer>
      }

      <StyledInputContainer label={`${t("chronos.app.components.editForm.mainGroup.place")} ${t(info.label)}`}>
        <StyledPlaceInput
          size='big'
          asyncDataFn={api.places.bind(api)}
          asyncDetailFn={api.place.bind(api)}
          value={props.place?.name || ''}
          place={props.place}
          onSelect={onPlace}
          userCoordinates={true}
          notice={['prognostics', 'syn_prognostics'].includes(props.type) ? natalPlaceMessage : undefined}
          matchPlace={
            ['prognostics', 'syn_prognostics'].includes(props.type) ?
              {
                matchKey: 'name',
                matchValue: props.natal?.place.name || '',
                matchMessage: t("chronos.app.mainGroup.cityFromNatal")
              } : undefined
            }
          mode={errorForField('place') ? 'error' : 'normal'}
          lang={i18n.language}
          t={t}
        />
      </StyledInputContainer>

      <StyledInputContainer label={t("chronos.mobile.components.editForm.date", { label: t(info.label) })}>
        <DateTimeInput
          size='big'
          type='date'
          value={props.date}
          onChange={onDate}
          hideIcon={true}
          utcMode
        />
      </StyledInputContainer>

      <Labels>
        <InputContainer label={t("chronos.mobile.components.editForm.time", { label: t(info.label) })}>
          <DateTimeInput
            size='big'
            type='time'
            hideIcon={true}
            value={props.time}
            onChange={onTime}
            utcMode
          />
        </InputContainer>
        <InputContainer label={t("chronos.app.components.editForm.mainGroup.timeZone")}>
          <GMTSelect size='big' value={props.gmt} onChange={props.setGMT} />
        </InputContainer>
      </Labels>

      {props.type === 'natal' &&
        <Cosmogram>
          <span>{t("chronos.app.components.editForm.mainGroup.cosmogram")}</span>
          <StyledSwitcher checked={props.cosmogram} onChange={props.setCosmogram} />
        </Cosmogram>
      }

      {(props.type === 'natal' || props.type === 'synastry') &&
        <Labels count={3}>
          <Label>
            <Radio
              value={null}
              label={t("chronos.app.components.editForm.mainGroup.noGender")}
              checked={gender === null}
              onChange={onGender}
            />
          </Label>
          <Label>
            <Radio
              value="female"
              label={t("chronos.app.components.editForm.mainGroup.gender.female")}
              checked={gender === 'female'}
              onChange={onGender}
            />
          </Label>
          <Label>
            <Radio
              value="male"
              label={t("chronos.app.components.editForm.mainGroup.gender.male")}
              checked={gender === 'male'}
              onChange={onGender}
            />
          </Label>
        </Labels>
      }

      {
        props.type === 'horar' &&
        <HigherContainer>
          <div className='group-item-content'>
            <h2 className='title'>{t("chronos.app.components.editForm.mainGroup.showAspects")}</h2>
          </div>
          {<div className='group-item-value'>{props.showAspects ? t("chronos.app.ui.on") : t("chronos.app.ui.off")}</div>}

          <div className="group-item-controls">
            <StyledSwitcher
              checked={props.showAspects}
              onChange={props.setShowAspects}
            />
          </div>
        </HigherContainer>
      }

      { 
        !['prognostics', 'relocation', 'soul', 'syn_prognostics'].includes(props.type) &&
        <ObjectСatalog onClick={onShowStarCatalog}>
          <span>{t("chronos.app.settings.objectCatalog")}</span>
          <span><ChevronRightIcon /></span>
        </ObjectСatalog>
      }

      {
        showObjectCatalog &&
        createPortal(
          <PopupBottom
            icon={<StarIcon />}
            title={t("chronos.app.settings.objectCatalog")}
            open={showObjectCatalog}
            onClose={() => setShowObjectCatalog(false)}
            modal={false}
            strong
          >
            <StarCatalog
              list={StarData}
              selected={props.fixedStars!}
              outsideMode={props.mode}
              dateTime={props.date}
              onSelect={onFixedStars}
            />
          </PopupBottom>,
          document.getElementById('appPopups')!
        )
      }

      {
        props.type === 'horar' &&
        <StyledSection
          title={t("chronos.app.components.editForm.additionalInfo")}
          isOpened={additionalIsOpened.value}
          switch={() => sectionsChanged('additional', additionalIsOpened.switch())}
          isLast={true}
        >
          <StyledHousesSystemSelect
            title={t("chronos.app.components.editForm.houseSystem")}
            value={housesSystem}
            setValue={setHousesSystem}
          />

          <HigherContainer className='hasSubtitle'>
            <div className='group-item-content'>
              <h2 className='group-item-title'>{t("chronos.app.settings.showHigherPlanets")}</h2>
              {<h3 className="group-item-subtitle">{t("chronos.app.settings.showHigherPlanets.inHorarMap")}</h3>}
            </div>
            {<div className='group-item-value'>{showHigherPlanets ? t("chronos.app.ui.on") : t("chronos.app.ui.off")}</div>}

            <div className="group-item-controls">
              <StyledSwitcher
                checked={showHigherPlanets}
                onChange={changeHigherPlanets}
              />
            </div>
          </HigherContainer>
        </StyledSection>
      }
    </Container>
  );
}

const Container = styled.div<{disabled: boolean}>`
  position: relative;
  z-index: 1;
  width: 100%;
	height: 100%;

  ${p => p.disabled && css`
    pointer-events: none;
    opacity: 0;
  `}
`

export const Label = styled.div`
  width: 100%;
  margin-top: 1rem;
`;

export const Labels = styled.div<{count?: number}>`
  position: relative;
  display: grid;
  grid-template-columns: repeat(${props => props.count || 2}, 1fr);
  grid-column-gap: 1.25rem;
  margin-top: 1em;
`;

const HigherContainer = styled.div`
  margin-top: 1.25rem;
  width: 100%;
	height: 100%;
  display: flex;

	.title {
		font-size: 1rem;
    font-weight: 500;
	}

	.hasSubtitle {
		align-items: end;
	}

  .group-item {
    &.pointer {
      cursor: pointer;
    }

    &-content {
      display: flex;
      flex-grow: 2;
      flex-direction: column;
      justify-content: center;
    }

    &-title {
      margin: 0;
      font-size: 0.75rem;
			font-weight: 500;
			color: var(--text-secondary);
    }

    &-subtitle {
      margin: 0;
      margin-top: 0.25rem;
      font-size: 0.875rem;
      font-weight: 500;
    }

    &-value {
      display: flex;
      align-items: center;
      margin-right: 1rem;
      font-size: 1rem;
      font-weight: 500;
    }

    &-controls {
      position: relative;
      display: flex;
      justify-content: start;
      align-items: center;

      svg {
        width: 1.5rem;
        height: 1.5rem;
        vertical-align: middle;
        transform: rotate(180deg);
      }
    }
  }
`

const StyledInputContainer = styled(InputContainer)`
  margin-top: 1em;

  .ch-field-notice {
    color: var(--text-secondary);
  }

  .user-coordinates {
    display: none;
  }
`;

const StyledPlaceInput = styled(PlaceInput)`
  position: relative;
  box-sizing: border-box;

  .place-input-item {
    display: block;
    position: relative;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    font-size: 0.875rem;
    
    text-wrap: balance;

    .coords {
      margin-left: 0.125rem;
      font-size: 0.9em;
    }


  }
`

const Cosmogram = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1em;
  font-size: 0.875rem;
  color: var(--text-secondary);
`;


const Title = styled.div`
  margin-bottom: 1rem 0rem;
  font-size: 1rem;
`;

const StyledSwitcher = styled(Switcher)`
  position: relative;
  margin-left: auto;
`;

const ObjectСatalog = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  cursor: pointer;

	span {
		font-size: 1rem;
		font-weight: 500;
	}

  & svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const StyledSection = styled(ExpandedSection)`
	margin-top: 1.25rem;
`

const StyledHousesSystemSelect = styled(HousesSystemSelect)`
	margin-top: 1.25rem;
`
