import { IBaseData, IFormData, CircleMode, IFixedStar, IMap, degToString, getSign, housesPower, isSynastry, planetsPower, signs, toDateTime, profileMode, show } from 'src/libs';
import dayjs from 'dayjs';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import StarsData from 'src/app/pages/settings/components/stars.json';
import astro, { CircleModes } from 'src/astro';
import HorarStarsData from 'src/guide/fixedStars';
import { getForm } from 'src/helpers/api';
import { useTranslation } from 'src/i18n/useTranslation';
import { updateForm } from 'src/store/reducers/forms/actions';
import { getActiveAstroProfile, getCurrentCustomization } from 'src/store/reducers/settings/selectors';
import { useSelector } from 'src/store/utils';
import DoubleTap from 'src/ui/DoubleTap';
import { PopupBottom } from 'src/ui/Popups/PopupBottom';
import styled from 'styled-components';
import { useAsyncMemo } from 'use-async-memo';
import { acccesOnlyForRu } from "../../../helpers/permissions";
import { smoothHorizontalScrolling } from '../../../utils';
import EditForm from '../dashboard/components/EditForm';
import Maps from './Maps';
import Footer from './components/Footer';
import Header from './components/Header';
import InaccessibleMap from "./components/InaccessibleMap";
import MapSelector from './components/MapSelector';
import Widgets from './components/Widgets';
import { IStrongs, IWidget, widgets as widgetsList } from './components/Widgets/data';
import { calculation } from './utils';
import { isLimitedAccess, isBaseTariff, isTrialAccess } from "src/store/localStorage/profile";

import { IHighlightsData } from './components/Circle';
import { getWorkspaceIndicator } from 'src/store/reducers/mapIndicator/selectors';
import { setInitSynastryTransits } from 'src/store/reducers/mapIndicator/actions';
import { EInitSynastryTransitsCommand } from 'src/store/reducers/mapIndicator/types';

export type TMode = 'natal' | 'prognostics' | 'synastry' | 'horar' | 'soul' | 'syn_prognostics';
export type TSaveFormMode = 'add' | 'set' | null

export interface IHighlight {
	map: CircleMode;
	type: 'aspect' | 'object' | 'object_ext' | 'house' | 'house_ext' | 'object_outer' | 'house_outer' | 'sign';
	id: number;
}
interface IInitialState {
	synastryModes: CircleMode[];
	prognosticsModes: CircleMode[];
	widgets: IWidget[];
	form: IFormData | null;
	natalMap: IMap | null;
	highlights: IHighlightsData;
	//description: IDescription | null;
	accessError: string;
	//prompt: IPrompt | null;
	pinnedAspects: number[];
	skipUpdate: boolean;
	mode: TMode;
	activeWidget: string | undefined;
	maps: IMap[] | [];
	strongs: IStrongs;
	currentMode: CircleMode;
	modes: CircleMode[];
}

export interface IFixedStarExt extends IFixedStar {
	constellation: string,
	constellationRu: string;
	sign: string;
	signRu: string;
	isHorar?: boolean;
}

const initialStrongs = {
	planets: [],
	houses: {
		houses: [],
		planets: [],
		power: []
	},
	aspects: []
};

const initialMode: CircleMode = 'natal';
const initialState = {
	synastryModes: ['syn_natal', 'compatibility1', 'partner1'] as CircleMode[],
	prognosticsModes: ['directions', 'solars', 'transits', 'prog_natal'] as CircleMode[], //'prog_prog'
	widgets: [],
	form: null,
	natalMap: null,
	highlights: {
		items: [],
		aspects: []
	},
	description: null,
	prompt: null,
	pinnedAspects: [],
	skipUpdate: true,
	accessError: "",
	access: {
		type: "private",
		showPersonalData: true,
	},
	mode: initialMode,
	activeWidget: undefined,
	maps: [],
	strongs: initialStrongs,
	currentMode: initialMode,
	modes: [initialMode],
};

export function getModes(mode: TMode): CircleMode[] {
	if (mode === 'synastry') return CircleModes.filter(isSynastry);
	else if (mode === 'prognostics') return ['directions', 'solars', 'transits', 'prog_natal']; // 'prog_prog'
	else if (mode === 'horar') return ['horar'];
	else if (mode === 'soul') return ['soul'];
	else return ['natal'];
}


export default memo(function WorkspacePage() {
	const params = useParams();
	const dispatch = useDispatch();
	const mapsRef = useRef<HTMLDivElement>(null);
	const workspaceRef = useRef<HTMLDivElement>(null);
	const resetRef = useRef<HTMLDivElement>(null);

	const [openFormPopup, setOpenFormPopup] = useState(false);
	const [saveFormMode, setSaveFormMode] = useState<TSaveFormMode>(null)
	const [openWidgetsPopup, setOpenWidgetsPopup] = useState(false);
	const [formPopupMode, setFormPopupMode] = useState<TMode>('natal');
	// const [forceVersion, setForceVersion] = useState<number>(0);
	const { t } = useTranslation();

	const [state, setState] = useState<IInitialState>(initialState);
	const showHorarAspects = useSelector(state => state.settings.settings.data.ui.instruments.cardSettingsForms.horar.showAspects)
	const id = params.id;
	const activeAstroProfile = useSelector(getActiveAstroProfile);
	const { data: personalization } = useSelector(getCurrentCustomization);


	const mapIndicator = useSelector(getWorkspaceIndicator)

	useEffect(() => {
		const onRectSelected = async () => {
			const form = await getFormData();
			if (!form) return;
			calcMap(form);
		}

		window.addEventListener('chronosRectSelected', onRectSelected);

		return () => {
			window.removeEventListener('chronosRectSelected', onRectSelected);
		};
	}, [])


	const calculate = async (form: IFormData, modes: CircleMode[]) => {
		let natalIndex = -1;
		const natalMode: CircleMode = 'natal';

		const maps = await Promise.all(
			modes.map(async (mode, index) => {
				const data = await calculation(
					mode, 
					form, 
					activeAstroProfile, 
					showHorarAspects,
					mapIndicator
				);

				if (!data) {
					return null;
				}

				const map = {
					mode,
					...data
				};

				if (mode === 'natal' || mode === 'syn_natal' || mode === 'prog_natal') {
					natalIndex = index;
				}

				return map;
			})
		);

		const filtered = maps.filter(map => !!map)

		const natalMap = natalIndex >= 0
			? maps[natalIndex]
			: await calculation(
				natalMode, 
				form, 
				activeAstroProfile, 
				showHorarAspects,
				mapIndicator
			)

		const strongs = natalMap
			? {
				planets: planetsPower(natalMap),
				houses: housesPower(natalMap),
				aspects: astro.strongAspects(natalMap)
			}
			: initialStrongs;

		return {
			strongs,
			maps: filtered,
			natalMap
		};
	};

	async function getFormData() {
		try {
			const form = await getForm(Number(id));
			document.title = `${form.name}, ${dayjs(form.natal.dt).format('DD.MM.YYYY')}`
			return form;
		} catch (e) {
			console.log(e);
			show({
				text: t('error.getForm'),
				timeout: 3000,
			})
			return null;
		}
	}

	const calcFixedStars = async (stars: string[], key: string): Promise<IFixedStarExt[]> => {
		const result: IFixedStarExt[] = [];

		for (const star of stars) {
			let tmpStar: IFixedStarExt;
			const allStarsData = StarsData.find((dataStar) => dataStar.astroName === star);

			let starData: any = key === 'horar'
				? (HorarStarsData.find((hStar) => hStar.name === star))
				: allStarsData;

			const dt = state.currentMode === 'partner1'
				? (state.form as any)['natal'].dt
				: (state.form as any)[key].dt

			const { lon } = await astro.star(dt, star);
			const sign = getSign(lon);

			tmpStar = {
				lon,
				ru: (allStarsData?.nameRu || starData?.title),
				en: (allStarsData?.nameEn || starData?.name),
				degree: degToString(lon),
				signRu: t(signs[sign].ru),
				sign: t(signs[sign].en),
				constellation: allStarsData?.constellation || '',
				constellationRu: allStarsData?.constellationRu || '',
				description: starData?.description || allStarsData?.description || ''
			}

			result.push(tmpStar);
		}

		return result;
	}

	async function calculateMaps(form: IFormData) {
		const modes = getModes(state.mode);

		const isLimited = isLimitedAccess();

		const widgets = widgetsList
			.filter(item => item.active)
			.sort((a, b) => a.order - b.order)
			.filter(w =>
				w.id === 'aspects-table' && !modes.includes('horar') && !modes.includes('soul') ||
				// w.id === 'orbise-corrector' && !modes.includes('horar') && !modes.includes('soul') ||
				w.id === 'coordinates-of-planets-and-houses' && (!isLimited && !modes.includes('horar') && !modes.includes('soul')) ||
				w.id === 'interpretation' && (!isLimited && modes.includes('natal') && !form!.cosmogram) ||
				w.id === 'horar-events' && modes.includes('horar') && !isLimited ||
				w.id === 'horar-speed' && modes.includes('horar') && !isLimited ||
				w.id === 'horar-essentials' && modes.includes('horar') && !isLimited ||
				w.id === 'formula-strength' && modes.includes('soul') && !isLimited ||
				w.id === 'ephemeris-formulas' && modes.includes('soul') && !isLimited ||
				w.id === 'horar-analysis' && modes.includes('horar') && !isLimited ||
				w.id === 'horar-light' && modes.includes('horar') && !isLimited ||
				w.id === 'formula-years' && modes.includes('soul') && !isLimited ||
				w.id === 'formula-soul' && modes.includes('soul') && !isLimited ||
				w.id === 'interpretation-soul' && modes.includes('soul') && !isLimited ||
				w.id === "rectification" && modes.includes("natal") && !isLimited ||
				[
					"house-formulas",
					"analyze",
					"autoprediction",
					"devplan",
					'dispositors',
					'coordinates-of-planets-and-houses',
				].includes(w.id) && (!isLimited && modes.includes("natal") && !form?.cosmogram) ||
				["autoprediction", "devplan"].includes(w.id) && isLimited ||
				(w.id === 'analyze' && state.mode === 'synastry') ||
				(w.id === 'strong-objects' && (!isLimited && !state.mode.includes('horar') && !state.mode.includes('soul')) && !state.form?.cosmogram)
			)
			.filter(w => !((w.id === "devplan" || w.id === "autoprediction" || w.id === "rectification") && ((form!.id === -1) || form?.name === 'chronos.app.tmpMap')))
			.filter(w => !(w.id === "rectification" && (isBaseTariff() || isTrialAccess())))
			.filter(w => {
				if (acccesOnlyForRu()) {
					return w
				} else {
					return w.id !== 'interpretation' && w.id !== 'interpretation-soul' && w.id !== 'autoprediction' && w.id !== 'devplan'
				}
			});

		const res = await calculate(form!, modes);
		// console.log('calculateMaps -', )

		let activeWidget = state.activeWidget;
		let currentMode = state.currentMode;

		if (modes.indexOf(currentMode) === -1) {
			if (mapsRef.current) mapsRef.current.scrollTo({ 'left': 0 });
			currentMode = modes[0];
			activeWidget = undefined;
		}

		// @ts-ignore
		setState(state => ({
			...state,
			...res,
			form,
			widgets,
			activeWidget,
			currentMode,
		}));

	}

	const onOpenFormPopup = (value: boolean, forMode?: TMode) => {
		forMode && setFormPopupMode(forMode);
		setOpenFormPopup(value);
	}

	const modeFixedStars = useAsyncMemo(async () => {
		let result: IFixedStarExt[] = [];

		let key: (keyof IFormData) | string = 'not-support';

		if (state.currentMode.includes('horar')) { key = 'horar' }
		else if (isSynastry(state.currentMode) && state.currentMode !== 'syn_natal') { key = 'synastry' }
		else if (['natal', 'syn_natal'].includes(state.currentMode)) { key = 'natal' }

		if (!activeAstroProfile?.fixedStars || !(state.form as any)?.[key]) {
		return {};
		}

		const modeStars = [...(activeAstroProfile?.fixedStars || {})[key]?.list || []];

		const showWithObjects = (activeAstroProfile?.fixedStars || {})[key]?.showWithObjects || false;

		if (!StarsData.length || !state.form || !modeStars.length) return {};

		result = await calcFixedStars(modeStars, key);

		return {
		fixedStars: result,
		showFixedStars: showWithObjects
		};

	}, [state.form, state.currentMode, activeAstroProfile]);

	useEffect(() => {
		const updateForm = async () => {
			const form = await getFormData();
			
			if (!form) return;
			
			setState(state => ({ ...state, form }))
			calcMap(form);
		}

		// обновляем временную карту
		if (id && Number(id) === -1) {
			updateForm()
		}
	}, [id]);

	useEffect(() => {
		if (id && Number(id) === -1) {
			if (state.mode && state.form) {
			  calculateMaps(state.form);
			  return; 
			}
			return;
		  }
		// дана команда добавить прогностику в синастрии
		if (mapIndicator.initSynastryTransits === EInitSynastryTransitsCommand.ADD && !state.form?.syn_prognostics) {
			onOpenFormPopup(true, 'syn_prognostics')
			return
		} 
		// дана команда удалить прогностику в синастрии
		else if (mapIndicator.initSynastryTransits === EInitSynastryTransitsCommand.DELETE && state.form?.syn_prognostics) {
			dispatch(updateForm({...state.form!, syn_prognostics: null}, () => {
				setInitSynastryTransits(EInitSynastryTransitsCommand.NONE)
				onUpdateFormNode('syn_prognostics', null)
			}));
			return
		
		} 
		
		
		if (state.mode && state.form) {
      		calculateMaps(state.form);
      		return;
    	}

		getFormData()
			.then(async form => {
				if (!form || form.error) {
					setState({
						...state,
						accessError: form?.error ?? ''
					})
				} else {
					setState(state => ({ ...state, form }))
					calculateMaps(form);
				}
			})
	}, [state.mode, state.form, showHorarAspects, mapIndicator]);


	const onUpdateForm = useCallback(async (newForm: any, mode?: TMode) => {
		resetRef.current!.click();

		setState({
			...state,
			form: newForm
		})

		const callback = () => {
			if (!mode) return;

			if (['synastry', 'prognostics', 'horar'].includes(mode!) && !newForm[mode]) {
				setMode('natal')
			} else if (mode !== 'syn_prognostics') {
				setMode(mode);
			}
		}


		setTimeout(() => {
			dispatch(updateForm(newForm!, callback));
		}, 500);
	}, [state]);

	const onUpdateFormNode = (node: TMode, nodeData?: IBaseData | null) => {
		if (!node) return;

		nodeData = nodeData || null;

		setState((state: any) => {
			return {
				...state,
				form: {
					...state.form,
					[node]: nodeData
				}
			}
		})
	}

	const calcMap = async (form: IFormData) => {
		const modes = getModes(state.mode);
		const res = await calculate(form, modes);

		// @ts-ignore
		setState(state => ({
			...state,
			maps: res.maps,
			strongs: res.strongs,
		}));
	}

	const setMode = useCallback((mode: TMode) => {
		resetRef.current!.click();
		setFormPopupMode(mode);
		setState(state => ({
			...state,
			mode
		}))
	}, [resetRef]);

	const addMode = useCallback((mode: TMode) => {
		setFormPopupMode(mode);
		setOpenFormPopup(true);
	}, [])

	const onOpenWidget = (id: string | undefined) => {
		setState(state => ({
			...state,
			activeWidget: id
		}))
		
		if (id) {
			queueMicrotask(() => setOpenWidgetsPopup(true));
		}
	}

	const widgetsData = useMemo(() => ({
		form: state.form!,
		maps: state.maps.map((map) => ({ ...map, ...modeFixedStars })),
		modes: state.modes,
		activeWidget: state.activeWidget,
		widgetMode: state.currentMode,
		aspectsTableMode: state.currentMode,
		strongs: state.strongs,
		pinnedAspects: [],
		activeAstroProfile,
		natalMap: state.natalMap!
	}), [state, modeFixedStars, activeAstroProfile]);

	const onWidgetChange = (key: string, data: any) => {
		
		const _form = state.form;

		switch (key) {
			case 'orbise-corrector':
				const modeValue = data?.normalizeMode ? data?.normalizeMode : data?.aspectsTableMode;
				const mode: string = data?.mode || profileMode(modeValue);
				(astro.settings.maps as any)[mode].orbiseCorrector = data.value;
				activeAstroProfile.maps[mode].orbiseCorrector = data.value;
				calculateMaps(_form!);
				break;
			case 'autoprediction':
				setState(state => {
					state.form!.gen = {
						autoprediction: data,
						rectification: state.form!.gen?.rectification || false,
						devplan: state.form!.gen?.devplan || false
					}
					return state;
				});
				break;
			case 'devplan':
				setState(state => {
					state.form!.gen = {
						autoprediction: state.form!.gen?.autoprediction || false,
						rectification: state.form!.gen?.rectification || false,
						devplan: data
					}
					return state;
				});
				break;
		}
	}

	const scrollToMap = (mode: CircleMode) => {
		resetRef.current!.click();

		const width = getComputedStyle(mapsRef.current!).width;
		const index = state.maps.findIndex(item => item.mode === mode);
		const currentIndex = state.maps.findIndex(item => item.mode === state.currentMode);
		const isIPhone = navigator.platform === 'iPhone'

		setState(state => ({ ...state, currentMode: mode }))

		if (isIPhone) {
			const distance = parseInt(width) * index - parseInt(width) * currentIndex
			smoothHorizontalScrolling(mapsRef, 1000, parseInt(width) * currentIndex, distance)
		} else {
			mapsRef.current!.scrollTo({ 'left': index * parseInt(width), behavior: 'smooth' })
		}
	}

	const onDoubleClick = (transformOptions: any, e: MouseEvent) => {
		const scale = transformOptions.scale;
		const newScale = Math.min(scale * 2, 8);

		const w = workspaceRef.current!.offsetWidth;
		const kX = (e.clientX - mapsRef.current!.getBoundingClientRect().x) / (w * scale);
		const cX = w * newScale * kX;
		const newX = cX - w / 2;

		const h = workspaceRef.current!.offsetHeight;
		const kY = (e.clientY - mapsRef.current!.getBoundingClientRect().y) / (h * scale);
		const cY = h * newScale * kY;
		const newY = cY - h / 2;

		transformOptions.setTransform(-newX, -newY, newScale)
	}

	const onCloseEditFormPopupBottom = useCallback(() => {
		setOpenFormPopup(false);

		// сменим режим на synastry, если форма была открыта для syn_prognostics
		if (formPopupMode === 'syn_prognostics') {
			setFormPopupMode('synastry');

			// если форма открывалась для добавления syn_prognostics, то сбрасываем команду инициализации syn_prognostics
			if (mapIndicator.initSynastryTransits === EInitSynastryTransitsCommand.ADD && !state.form?.syn_prognostics) {
				setInitSynastryTransits(EInitSynastryTransitsCommand.NONE)
			}
		}

	}, [formPopupMode, mapIndicator]);

	
	if (state.accessError) {
		return <InaccessibleMap accessError={state.accessError} />
	}

	if (!state.form || state.maps.length === 0) return null;


	const subtitle = `
    ${toDateTime(state.form?.natal?.dt).date},
    ${toDateTime(state.form?.natal.dt).time},
    ${state.form?.natal?.place.name}
  	`;

	// if (state.form && !state.form.access.permissions.includes("BASE")) {
	// 	return <AccessDenied />;
	// }

	
	return <>
		<Container>
			<Header
				title={t(state.form.name)}
				subtitle={subtitle}
				setOpenFormPopup={setOpenFormPopup}
				setSaveFormMode={setSaveFormMode}
				form={state.form}
			/>

			<Workspace ref={workspaceRef}>
				<TransformWrapper options={{ limitToBounds: true }} doubleClick={{ mode: 'zoomIn' }}>
					{({ ...transformOptions }) => (
						<DoubleTap action={(e: any) => onDoubleClick(transformOptions, e as MouseEvent)}>

							<TransformComponent>

								<MapsSwipeContainer>
									<MapsContainer ref={mapsRef} className={'MapsContainer'}>
										<Maps
											form={state.form!}
											maps={state.maps.map((map) => ({ ...map, ...modeFixedStars }))}
											strongs={state.strongs}
											natal={null}
											highlights={state.highlights}
											pinnedAspects={widgetsData.pinnedAspects}
											onChanged={() => void(0)}
											currentMode={state.currentMode}
											aspectsTableMode={state.currentMode}
											personalization={personalization}
											t={t}
										/>
									</MapsContainer>
								</MapsSwipeContainer>

							</TransformComponent>

							<div onClick={() => transformOptions.setDefaultState()} ref={resetRef} style={{ display: 'none' }}></div>

						</DoubleTap>
					)}
				</TransformWrapper>

				{state.maps.length > 1 &&
					<MapSelectorContainer>
						<MapSelector
							form={state.form}
							mode={state.mode}
							maps={state.maps || []}
							current={state.currentMode}
							onChangeMode={scrollToMap}
							setOpenFormPopup={onOpenFormPopup}
							isLocked={!state.form.access.isPersonal}
						/>
					</MapSelectorContainer>}

			</Workspace>

			<Footer
				mode={state.mode}
				current={state.currentMode}
				setMode={setMode}
				addMode={addMode}
				form={state.form}
				widgets={state.widgets}
				onOpenWidget={onOpenWidget}
				updateForm={onUpdateForm}
				calcMap={calcMap}
				setOpenFormPopup={setOpenFormPopup}
				setSaveFormMode={setSaveFormMode}
			/>
		</Container>
		

		<PopupBottom open={openFormPopup} onClose={onCloseEditFormPopupBottom} back={true}>
			<EditForm
				formId={Number(id)}
				form={state.form}
				mode={formPopupMode}
				open={openFormPopup}
				onAddNode={(nodeData, node) => onUpdateFormNode(node, nodeData)}
				onRemoveNode={(node) => onUpdateFormNode(node)}
				onUpdateForm={onUpdateForm}
				saveFormMode={saveFormMode}
				onClose={onCloseEditFormPopupBottom}
			/>
		</PopupBottom>

		<PopupBottom open={openWidgetsPopup} onClose={() => {setOpenWidgetsPopup(false); onOpenWidget(undefined) }}>
			{
				Boolean(state.form && (state.form[formPopupMode] || formPopupMode === 'soul') && state.widgets.length > 0) &&
				<Widgets
					key={`widgets_${state.form.natal.dt}${state.form.prognostics?.dt}${state.form.synastry?.dt}${state.form.syn_prognostics?.dt}${state.form.soul?.dt}${state.form.horar?.dt}`}
					currentMode={state.currentMode}
					widgets={state.widgets}
					active={widgetsData.activeWidget}
					onOpenWidget={onOpenWidget}
					widgetsData={widgetsData}
					onWidgetChange={onWidgetChange}
				/>
			}
		</PopupBottom>
	</>
});

const Container = styled.div`
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  height: 100%;
  width: 100%;

  .react-transform-component {
    width: 100% !important;
    height: 100% !important;
  }

  .react-transform-element {
    height: 100% !important;
    width: 100% !important;
  }
`;

const Workspace = styled.div`
  position: relative;
  width: calc(100% + 2rem);
  left: -1rem;
  overflow: auto;
  display: flex;
  align-items: center;
`;

const MapsSwipeContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const MapsContainer = styled.div`
  height: 100%;
  width: calc(100vw);
  overflow: hidden;
`;

const MapSelectorContainer = styled.div`
  width: 100vw;
  position: fixed;
  left: 0rem;
  bottom: 3.5rem;
`;



/*function getModeSwipe(increment: number, currentMode: CircleMode, modes: CircleMode[]) {
	let index = modes.findIndex(item => item === currentMode);
	let mode: CircleMode;
	if(increment > 0 && index < modes.length - 1) mode = modes[++index];
	if(increment < 0 && index > 0) mode = modes[--index];
	return {index, mode: mode!};
}*/

/*const swipeHandlers = useSwipeable({
	 onSwipedLeft: (e) => state.maps.length > 1 && swipeAndCall(e, () => {
		 const nextMode = getModeSwipe(1, state.currentMode, state.modes);
		 if(nextMode.mode) {
			 const width = getComputedStyle(mapsRef.current!).width;
			 setState(state => ({...state, currentMode: nextMode.mode}))
			 mapsRef.current!.scrollTo({ 'left': nextMode.index * parseInt(width) + (16 * nextMode.index), behavior: 'smooth' })
		 }
	 }),
	 onSwipedRight: (e) => state.maps.length > 1 && swipeAndCall(e, () => {
		 const nextMode = getModeSwipe(-1, state.currentMode, state.modes);
		 if(nextMode.mode) {
			 const width = getComputedStyle(mapsRef.current!).width;
			 setState(state => ({...state, currentMode: nextMode.mode}))
			 mapsRef.current!.scrollTo({ 'left': nextMode.index * parseInt(width) + (16 * nextMode.index), behavior: 'smooth' })
		 }
	 }),
 });*/
//  {...swipeHandlers}
