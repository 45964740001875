import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import {Button, IPayType, IPromocodeSubscriptionAction, PaymentCardPicker} from 'src/libs';
import TariffTextBlock from './TariffTextBlock';
//import { RowsIcon } from 'assets/icons/system/index';
import LineHorizontal from 'src/ui/LineHorizontal';
import Tariff from './Tariff';
import { useSelector } from 'src/store/utils';
import {
  getFreeTariffId,
  getFreeTariffPeriodInfo,
  getIsDowngrade,
  getShortTariffs,
  getTariffWithDiscount,
  getUserTariffId,
  paymentsFullInfo,
} from 'src/store/reducers/payments/selectors';
import { NavigationPoint } from 'src/ui/NavigationPoints.tsx';
import { descs } from 'src/store/reducers/payments/types';
import { useDispatch } from 'react-redux';
import { changeAutoPayments, getPayments, getPaymentsFullInfo, subscriptionChange } from 'src/store/reducers/payments/actions';
import ConfirmPopupButton from 'src/ui/Popups/ConfirmPopupBottom';
import ResultPopup from 'src/ui/Popups/ResultPopup';
import api from 'src/api';
import { useTranslation } from 'src/i18n/useTranslation';
import { useCurrency } from 'src/hooks';
import { getIokaLink } from 'src/helpers/payments';
import { getUtmLs } from 'src/helpers/localStorage';
import { IPaymentCardMethod, initPaymentMethod, paymentMethods } from './helpers';
import { getCurrencySymbol } from 'src/utils';
import { Policy } from 'src/components/payment/Policy';
import { transformLanfuageForTipTop } from 'src/helpers/language';

const payTypes: Array<IPayType> = [
  { title: "chronos.mobile.settings.payments.payTipes.perMonth.title", subtitle: "chronos.app.settings.inMonth", value: 1, desc: "chronos.app.settings.payment.monthlyPayment.desc" },
  { title: "chronos.mobile.settings.payments.payTipes.annually.title", subtitle: "chronos.mobile.settings.payments.payTipes.annually.subtitle", value: 12, badge: '-20%', desc: "chronos.app.settings.payment.annualPayment.desc" }
];

export default function TariffChange() {
  const region = useCurrency();
  const dispatch = useDispatch();

  const [activePayType, setActivePayType] = useState(1);
  const [activeTariff, setActiveTariff] = useState(1);
  const [confirmUpgradeTariff, setConfirmUpgradeTariff] = useState(false);
  const [paymentResult, setPaymentResult] = useState<'success'|'failed'>();
  const [actionForConfirm, setActionForConfirm] = useState<'changeTariff' | 'changeAutoPayments' | null>(null);
  const [paymentMethod, setPaymentMethod] = React.useState<IPaymentCardMethod>(initPaymentMethod(region.region));

  const userTariffId = useSelector(getUserTariffId);
  const shortTariffs = useSelector(getShortTariffs);
	const filteredShortTariffs = shortTariffs.filter(tariff => paymentMethod.code === tariff.currencyCode)
  const tariffWithDiscount = useSelector(getTariffWithDiscount);
  const freeTariffId = useSelector(getFreeTariffId);
  const freeTariffPeriodInfo = useSelector(getFreeTariffPeriodInfo);
  const info = useSelector(paymentsFullInfo).data!;

  const currentPayType = payTypes.find(item => item.value === activePayType)!;
  const yearDiscount = (currentPayType.value === 12) && 20;
  const currentTariff = (filteredShortTariffs as any)[activeTariff];
  const currentTariffDesc = descs[currentTariff.id];
  const isExpired = new Date(info.expiryDate) < new Date();
  const isRecurrentDiscount = info.promos ? (info.promos[0].actions as IPromocodeSubscriptionAction).recurrent : null;

  const isDowngrade = useSelector(s => getIsDowngrade(s, currentTariff.id));

  const { t } = useTranslation();

  async function pay(method: 'auth' | 'charge') {
    const result = await api.payment({
      type: 'subscribe',
      subscription: currentTariff.id,
      period: currentPayType?.value,
      country: paymentMethod.country,
      language: transformLanfuageForTipTop(localStorage.getItem('i18nextLng')),
      backUrl: getIokaLink('back'),
      failUrl: getIokaLink('fail'),
      successUrl: getIokaLink('success'),
      comment: getUtmLs(),
    }, method);

    if (result.failed) {
			setPaymentResult('failed');
		} else {
			dispatch(getPaymentsFullInfo);
      dispatch(getPayments);
      setPaymentResult('success');
		}
  }

  async function change() {
    try {
      dispatch(subscriptionChange([currentTariff.id], () => {
        dispatch(getPaymentsFullInfo);
        setPaymentResult('success');
      }));
    } catch (e) {
      setPaymentResult('failed');
    }
  }

  async function paymentAction() {
    // меняем на меньший тариф
    if (userTariffId && !isExpired && isDowngrade) {
      info.robocassaUser ? pay('auth') : change();
      // меняем на больший или продлеваем
    } else if (userTariffId && !isExpired && !isDowngrade) {
      setConfirmUpgradeTariff(true);
      // покупаем первый раз
    } else if (!userTariffId) {
      pay('charge');
    }
  }

  const buttonStart = <Button onClick={() => {
    setPaymentResult(undefined);
    window.location.href = '/dashboard';
  }} color="var(--color-blue)" key="button_1">{t("chronos.app.settings.payment.selectedFormations.startEnjojing")}</Button>;
  const buttonRestart = <Button onClick={() => {
      setPaymentResult(undefined);
    }} color="var(--color-blue)" key="button_2">{t("chronos.app.settings.payment.selectedFormations.repeatPayment")}</Button>;
  const buttonReturn = <Button onClick={() => {
      setPaymentResult(undefined);
      //onClose(); // TODO:
  }} color="transparentWithBorder" key="button_3s">{t("chronos.app.settings.payment.selectedFormations.backToSettings")}</Button>;

  const onChangeSlider = (index: number) => {
    setActiveTariff(index);
  }
  const renderIndicator = (clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void, isSelected: boolean) => {
    return <NavigationPoint active={isSelected} onClick={clickHandler}/>
  }

  const Slider = useCallback(() =>
    <Carousel
      showArrows={false}
      showStatus={false}
      centerMode={true}
      infiniteLoop={false}
      selectedItem={1}
      autoPlay={false}
      showThumbs={false}
      renderIndicator={renderIndicator}
      onChange={onChangeSlider}
      onClickItem={onChangeSlider}
      interval={100000} // TODO:
    >
      {filteredShortTariffs.map(item => {
        return <TariffContainer key={item.id} >
          <Tariff
            data={item}
            activePayType={activePayType}
            active={userTariffId === item.id}
            free={freeTariffId === item.id}
            freeText={t("chronos.mobile.settings.payments.tariffChange.then", { freeTariffPeriodInfo: freeTariffPeriodInfo, sum: item.sum })}
            arrow={false}
						currency={getCurrencySymbol(paymentMethod.code)}
          />
        </TariffContainer>
      })}
    </Carousel>
  , [filteredShortTariffs, yearDiscount]);

  const onChangeAutoPayments = () => dispatch(changeAutoPayments(!info.autoPayment))

  const onActionForConfirm = (action: string) => {
    switch (action) {
      case 'changeTariff': {
        paymentAction()
        break
      }
      case 'changeAutoPayments': {
        onChangeAutoPayments()
        break
      }
    }
  }

	const onChangeSubscription = () => {
		if (info.autoPayment && tariffWithDiscount?.includes(userTariffId) && isRecurrentDiscount) {
			setActionForConfirm('changeAutoPayments')
		} else {
			dispatch(changeAutoPayments(!info.autoPayment));
		}
	}

  return <Container>
    <TitleContainer>
      <Title>{t("chronos.app.settings.paymentDetails.chooseTariff")}</Title>
    </TitleContainer>
    <ButtonsContainer>
      {payTypes.map(item => {
        const isActive = (item.value === activePayType);
        return <Button key={item.value} color={isActive ? 'var(--color-blue)' : 'transparent'} onClick={() => { setActivePayType(item.value) }}>
          {t(item.title)} {item.badge && <span className={(isActive && 'active') || undefined}>{item.badge}</span>}
        </Button>
      })}

    </ButtonsContainer>

    <TariffsContainer className="no-swipe">
      {Slider()}
    </TariffsContainer>

    {/*<CompareLink onClick={() => {}}>
      Сравнить тарифы <RowsIcon />
    </CompareLink>*/}

    <TariffTextBlock title={t(currentTariff.name)} subtitle={t("chronos.app.settings.payment.selectedFormations.tariff")}>{t(currentTariffDesc)}</TariffTextBlock>
    <LineHorizontalWrapper><LineHorizontal /></LineHorizontalWrapper>

    <TariffTextBlock title={t(currentPayType.title)} subtitle={t("chronos.app.settings.payment.selectedFormations.payment.nom")}>{t(currentPayType.desc)}</TariffTextBlock>
    <LineHorizontalWrapper><LineHorizontal /></LineHorizontalWrapper>

		<StyledPaymentCardPicker items={paymentMethods}
														 value={paymentMethod}
														 onChange={(value: IPaymentCardMethod) => setPaymentMethod(value)}
														 t={t}
														 showInfo/>

    <SumContainer>
      <SubTitle>{t("chronos.app.settings.payment.selectedFormations.total")}</SubTitle>
      <Price>{currentTariff.sum[activePayType].final * currentPayType.value}{getCurrencySymbol(paymentMethod.code)}</Price>
    </SumContainer>

    {
			userTariffId === currentTariff.id && <Button color="var(--color-blue)"
      																						 onClick={onChangeSubscription}>
        {info.autoPayment ? t("chronos.app.settings.unsubscribe") : t("chronos.app.settings.renewSubscription")}
    	</Button>
		}

    {userTariffId !== currentTariff.id && <Button color="var(--color-blue)"
      onClick={() => {
        if (tariffWithDiscount && tariffWithDiscount.includes(userTariffId) && isRecurrentDiscount) {
          setActionForConfirm('changeTariff')
        } else {
          paymentAction()
        }
      }}>
        {isDowngrade ? t("chronos.app.settings.payment.SelectedTariff.goToTariff") : t("chronos.app.payments.goToPayment")}
    </Button>}

    <Policy paymentMethod={paymentMethod} />

    <ConfirmPopupButton
      open={confirmUpgradeTariff}
      onClose={() => setConfirmUpgradeTariff(false)}
      title={t("chronos.app.settings.selectedTariff.switchToTariff")}
      onConfirm={() => {
        setConfirmUpgradeTariff(false);
        info.robocassaUser ? pay('auth') : pay('charge');
      }}
      confirmText={t("base.confirm")} strong={true}>
        {t("chronos.app.settings.selectedTariff.currentSubscriptionWillBeReset")}
    </ConfirmPopupButton>

    {paymentResult === 'success' && <ResultPopup
      open={true}
      title={t("chronos.app.settings.selectedFormations.paymentWasSuccessful")}
      subtitle={t("chronos.app.settings.selectedFormations.welcome")}
      success={true}
      buttons={[buttonStart]}
    />}

    {paymentResult === 'failed' && <ResultPopup
      open={true}
      title={t("chronos.app.settings.selectedFormations.somethingWentWrong")}
      subtitle={t("chronos.app.settings.selectedFormations.paymentFailed")}
      success={false} buttons={[buttonRestart, buttonReturn]}
    /> }

    <ConfirmPopupButton
      open={!!actionForConfirm}
      onClose={() => setActionForConfirm(null)}
      title={t("chronos.app.settings.selectedTariff.switchToTariff")}
      onConfirm={() => {
        onActionForConfirm(actionForConfirm!)
        setActionForConfirm(null)
      }}
      confirmText={t("chronos.app.settings.payment.tariff.changeTariff")} strong={true}>
      {t("chronos.app.settings.payment.tariff.changeTariff.confirmationText")}
    </ConfirmPopupButton>
  </Container>
}

const Container = styled.div`
  padding: 0rem 1rem;
  color: var(--text-primary);
  padding-bottom: 0.625rem;
  width: 100%;
  white-space: normal;
`

const TitleContainer = styled.div`
  text-align: center;
`
const Title = styled.div`
  margin-top: 0.75rem;
  font-size: 1.5rem;
  line-height: 1.75rem;
`

const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: var(--input-background);
  margin-top: 1.3125rem;
  border-radius: 7px;
  padding: 3px;

  span {
    color: var(--color-blue);
    &.active{
      color: inherit;
    }
  }

  button {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 0.875rem;
    border-radius: 5px;
  }
`
const CompareLink = styled.a`
  display: block;
  text-align: center;
  color: var(--text-secondary);
  margin-top: 1rem;
  font-size: 1rem;
  line-height: 1.1875rem;

  > svg {
    color: var(--text-secondary);
    margin-left: 0.5rem;
    display: inline-block;
    vertical-align: middle;
  }
`

const TariffsContainer = styled.div`
  text-align: center;

  .control-dots{
    display: flex;
    margin: 0;
    justify-content: center;
    position: absolute;
    bottom: 0;
  }
`

const TariffContainer = styled.div`
  padding: 0 0.375rem 1.25rem 0.375rem;
`


const LineHorizontalWrapper = styled.div`
  margin-top: 1rem;
`

const SumContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const SubTitle = styled.div`
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: var(--text-secondary);
`
const Price = styled.div`
  font-size: 1.5rem;
  line-height: 1.75rem;
  margin-bottom: 0.375rem;
  margin-top: 0.5rem;
`

const StyledPaymentCardPicker = styled(PaymentCardPicker)`
	.switcher_button {
		font-size: 0.875rem;
		text-align: center;
		height: 3rem;
	}
`
