
import React, { SyntheticEvent, useState } from 'react';
import styled, { css } from 'styled-components';

import { LeftTriangleIcon } from 'src/assets/icons/arrows/index';

import { OkCircleInactiveIcon } from 'src/assets/icons/system/index';
import { OkCircleActiveIcon } from 'src/assets/icons/system/index';

import eventsData from './events';
import { t } from 'i18next';
import { fillNumber } from 'src/libs';

const checkDate = (s: string) => {
  function isValidDate(d: any) {
    return d instanceof Date && !isNaN(+d);
  }

  const p = s.split('.');
  const date = new Date(+p[2], +p[1] - 1, +p[0]);
  const string = `${fillNumber(date.getDate())}.${fillNumber(date.getMonth() + 1)}.${date.getFullYear()}`
  return {
    isDate: isValidDate(date),
    date: string
  }
}
const validateDate = (input: string) => {
  const digits = input.replace(/[^\d]/g, "").slice(0, 8);
  
  const day = digits.slice(0, 2);
  const month = digits.slice(2, 4);
  const year = digits.slice(4, 8);

  let validateMonth = month.length === 2 ? Math.max(Math.min(Number(month), 12), 1).toString().padStart(2, '0') : month;
  let validateYear = year.length === 4 ? Math.min(Math.max(Number(year), 1200), new Date().getFullYear()).toString() : year;

  let maxDay = 31;
  if (validateMonth && validateYear && validateMonth.length === 2 && validateYear.length === 4) {
    maxDay = new Date(Number(validateYear), Number(validateMonth), 0).getDate();
  }
  let validateDay = day.length === 2 ? Math.max(Math.min(Number(day), maxDay), 1).toString().padStart(2, '0') : day;

  return [validateDay, validateMonth, validateYear].filter(Boolean).join('.');
}

export default function EventAdd({
  onClose,
  onAdd
}: {
  onClose():void;
  onAdd(ev: {id: number, weight: number, date: string}): void;
}){
  const [filter, setFilter] = useState('');
  const [event, setEvent] = useState<any>();
  const [weight, setWeight] = useState(5);
  const [date, setDate] = useState('');

  const onChange = (e: SyntheticEvent) => {
    if(event) return;
    setFilter((e.target as HTMLInputElement).value);
  }

  const changeDate = (e: SyntheticEvent) => {
    const value = (e.target as HTMLInputElement).value;
    const date = validateDate(value);
    setDate(date);
  }

  const onSelectEvent = (item: any) => {
    setEvent(item);
    setFilter(item.name);
    setWeight(item.weight);
  }

  const events_: any = Object.entries(eventsData.events).map(item => {
    return {
      ...item[1], 
      id: item[0],
      name: t(item[1].name),
      synonims: t(item[1].synonims)
    };
  })
  const events = events_.filter((item: any) => {
    const f = filter.toLowerCase();
    return item.name.toLowerCase().indexOf(f) !== -1 || item.synonims.toLowerCase().indexOf(f) !== -1
  })
  .slice(0, 6)
  .reverse();

  const onSubmitClick = () => {
    if(!isActive) return;
    if(!event) {
      onSelectEvent(events[0]);
    } else {
      onAdd({
        id: event.id, 
        weight, 
        date
      });
      onClose();
    }
  }
  
  const isActive = 
    !event && filter.length >= 1 && events.length > 0 ||
    event && checkDate(date).isDate;
  
  const SubmitButton = <Button onClick={onSubmitClick}>
    {isActive && <OkCircleActiveIcon />}
    {!isActive && <OkCircleInactiveIcon />}
  </Button>

  const minusWeight = () => {
    setWeight(weight => weight - 1);
  }

  const plusWeight = () => {
    setWeight(weight => weight + 1);
  }

  return <Container>
    <Header>
      <LeftTriangleIcon onClick={() => onClose()} />
      <HeaderContent>
        <SubTitle>{t("chronos.mobile.instruments.widgets.interpretation.eventAdd.startToInput.title")}</SubTitle>
        <input placeholder={t("chronos.mobile.instruments.widgets.interpretation.eventAdd.startToInput.placegolder")} value={filter} onChange={onChange} />
      </HeaderContent>
      {SubmitButton}
    </Header>

    <Content>
    {!event && isActive && <div>
        {events.map((item: any) => <Item key={item.id} onClick={() => onSelectEvent(item)}>
          <span>{item.weight}</span>
          <div>{item.name}</div>
        </Item>)} 
      </div>}
      
      {event && <div>
        <Group>
          <SubTitle>{t("chronos.app.rectification.significance")}</SubTitle>
          <Counter>
            <Circle active={weight > 1} onClick={minusWeight}>-</Circle>
            <span>{weight}</span>
            <Circle active={weight < 5} onClick={plusWeight}>+</Circle>
          </Counter>
        </Group>
        <Group>
          <SubTitle>{t("chronos.mobile.instruments.widgets.interpretation.eventAdd.startToInput.whenHappen")}</SubTitle>
          <input
            type="text"
            value={date} 
            onChange={changeDate} 
            placeholder={t("chronos.mobile.dateFormat.DDMMYYYY")}
          />
        </Group>
      </div>}
    </Content>

  </Container>
}

const Container = styled.div`
  min-height: 80vh;
  display: grid;
  grid-template-rows: min-content 1fr;
  flex-direction: column;
`

const Content = styled.div`
  overflow: scroll;
`

const Item = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-gap: 0.8rem;
  color: var(--text-secondary);
  font-size: 0.875rem;
  margin-bottom: 0.675rem;

  > span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    background: var(--bg-element-neutral);
    color: var(--text-primary);
    font-size: 0.75rem;
    padding-top: 1.5px;
  }
`


const Header = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  grid-gap: 0.5rem;
  align-items: center;
  margin-bottom: 1rem;
`
const HeaderContent = styled.div`

  > input {
    width: 100%;
    font-size: 1rem;
    text-overflow: ellipsis;

    ::placeholder {
      color: var(--text-secondary);
    }
  }
`

const Button = styled.button`
  background: none;
  border: none;
`

const SubTitle = styled.div`
  font-size: 0.75rem;
  color: var(--text-third);
  margin-bottom: 0.125rem;
`

const Group = styled.div`
  padding-left: 2rem;
  margin-bottom: 1rem;
`

const Counter = styled.div`
  display: grid;
  grid-template-columns: min-content min-content min-content;
  grid-gap: 0.75rem;
  align-items: center;
`

const Circle = styled.div<{active: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  color: var(--text-primary);
  background: var(--bg-100);
  font-size: 1.25rem;

  ${p => !p.active && css`
    pointer-events: none;
    color: var(--text-third);
  `}
`