import React from 'react';
import styled from 'styled-components';

import { ObjectType, IPoint, getAspectsPairs, PairAspects, CircleMode, IAspect, getSign, IMap, isSynastry, PrognosticsMode, IAstroSettings } from 'src/libs';

import { aspectsIcons, objectsIcons } from 'src/helpers/icons';

import { IWidgetData } from '../data';
import Icon from './Icon';
import OrbiseCorrector from '../OrbiseCorrector';
import { IHighlight } from 'src/app/pages/workspace';
import { isCompatibility, isPartner, extractMode } from '../../../utils';
import { connect } from 'react-redux';

import { getWorkspaceIndicator } from 'src/store/reducers/mapIndicator/selectors';
import { getActiveAstroProfile } from 'src/store/reducers/settings/selectors';
import { IMapIndicatorState, ECompatibilityTransitMode } from 'src/store/reducers/mapIndicator/types';
import { TWidgetCircleMode } from '../index';


const axes = [1, 2, 3, 5, 6, 10];
const axesObjects = [ObjectType.House1, ObjectType.House2, ObjectType.House3, ObjectType.House5, ObjectType.House6, ObjectType.House10];

const axesPairs: {
  [key: number]: [number, number];
} = {
  [ObjectType.House1]: [1, 2],
  [ObjectType.House2]: [2, 2],
  [ObjectType.House3]: [3, 2],
  [ObjectType.House5]: [4, 2],
  [ObjectType.House6]: [5, 2],
  [ObjectType.House10]: [6, 2],

  [ObjectType.House7]: [1, 1],
  [ObjectType.House8]: [2, 1],
  [ObjectType.House9]: [3, 1],
  [ObjectType.House11]: [4, 1],
  [ObjectType.House12]: [5, 1],
  [ObjectType.House4]: [6, 1]
};

function headerIcon(i: number) {
  return (
    <Icon
      icon={i <= ObjectType.Chiron ? objectsIcons[i] : null}
      text={i <= ObjectType.Chiron ? '' : axes[i - ObjectType.Chiron - 1].toString()}
    />
  );
}

function cellClassName(i: number, dir: 'h' | 'v') {
  const diff = i - ObjectType.Chiron;
  // в switch кроме 11 и 12, были добавляны для автотестов 
  switch (i) {
    case 19: return `${dir}-axis House10`
    case 18: return `${dir}-axis House6`
    case 17: return `${dir}-axis House5`
    case 16: return `${dir}-axis House3`
    case 15: return `${dir}-axis House2`
    case 14: return `${dir}-axis House1`
    case 12: return 'lilith'
    case 11: return 'south-node'
    case 10: return 'NorthNode'
    case 9: return 'Pluto'
    case 8: return 'Neptune'
    case 7: return 'Uranus'
    case 6: return 'Saturn'
    case 5: return 'Jupiter'
    case 4: return 'Mars'
    case 3: return 'Venus'
    case 2: return 'Mercury'
    case 1: return 'Moon'
    case 0: return 'Sun'
    default: return diff < 0 ? '' : diff ? `${dir}-axis` : 'chiron';
  }
}

function getCellIndex(i: ObjectType) {
  const gap = (ObjectType.House1 - ObjectType.Chiron) - 1;

  if (i <= ObjectType.Chiron) {
    return {
      id: i + 1,
      w: 1
    };
  } else if (i >= ObjectType.House1) {
    const p = axesPairs[i];

    return {
      id: p[0] + ObjectType.House1 - gap,
      w: p[1]
    };
  }

  // const p = axesPairs[i];
  //
  // return {
  //   id: p[0] + ObjectType.House1,
  //   w: p[1]
  // };
}

interface IAspectsTableCell {
  cell: React.RefObject<HTMLTableDataCellElement>;
  icon: React.RefObject<Icon>;
  aspectId: number;
  weight: number;
}

interface AspectsTableProps {
  subMode: TWidgetCircleMode;
  data: IWidgetData;
  onChanged(key: string, value: any): void;
  openedWidget: boolean;
  activeProfileId: number;
  mapIndicator: IMapIndicatorState;
  astroProfile: IAstroSettings;
}

export function highlightAspect(map: CircleMode, aspect: IAspect, hasExt: boolean = false, hasOuter: boolean = false, comatibilityReversed?: boolean): IHighlight[] {
  if (!aspect) { return [] }

  const type: any = (obj: ObjectType, isExt = false, isOuter = false) => ({
    id: obj >= ObjectType.House1 ? obj - ObjectType.House1 : obj,
    type: `${obj >= ObjectType.House1 ? 'house' : 'object'}${isExt && !isOuter ? '_ext' : isOuter ? '_outer' : ''}`
  });

  let result: IHighlight[] = [];

  // обычный аспект
  if(!('obj3' in aspect)) {
    result = [
      {
        id: aspect.id,
        type: 'aspect',
        map
      },
      {
        ...type(aspect.obj1, hasOuter ? hasExt : false),
        map
      },
      {
        ...type(aspect.obj2, hasExt, hasOuter),
        map
      },
      {
        type: 'sign',
        id: getSign(aspect.lon1),
        map
      },
      {
        type: 'sign',
        id: getSign(aspect.lon2),
        map
      }
    ] as IHighlight[]
    // аспект на аспект или (транзиты в синастрии в режиме 2)
  } else {  
    const ErisId = 15;
    result = aspect.obj3 === ErisId // Если в транзитных объектах приходит Eris, то не подсвечиваем такие аспекты
    ? [] 
    :[
      {
        id: aspect.id,
        type: 'aspect',
        map
      },
      ((aspect.obj1! >= 0)
        ? {
            ...type(aspect.obj1),
            map
          }
        : undefined
      ),
      ( aspect.obj2! >= 0
        ? {
            ...type(aspect.obj2, true), //TODO второй аргумент - hasExt. Его считаем для аспекта с домом если это синастрическая прогностика
            map
          }
        : undefined
      ),
      
      {
        ...type(aspect.obj3, false, true),
        map
      },
      
      (aspect.obj1! >= 0
        ? {
            type: 'sign',
            id: getSign(aspect.lon1),
            map
          }
        : undefined
      ),
      
      (aspect.obj2! >= 0
        ? {
            type: 'sign',
            id: getSign(aspect.lon2),
            map
          } 
        : undefined
      ),
      {
        type: 'sign',
        id: getSign(aspect.lon3!),
        map
      }
    ].filter(h => h) as IHighlight[]
  }

  return result;
}

export function hasExt(mode: CircleMode) {
  if(isCompatibility(mode) || isPartner(mode)) {
    return true;
  }
  return ['transits', 'directions', 'solars', 'prog_natal'].includes(mode);
}

class AspectsTable extends React.Component<AspectsTableProps> {
  private readonly _ref = React.createRef<HTMLTableElement>();
  private readonly _cells: IAspectsTableCell[][] = [];
  private _prevHover: IPoint | null = null;

  constructor(props: AspectsTableProps) {
    super(props);

    for (let i = 0; i < 21; i++) {
      const row: IAspectsTableCell[] = [];

      for (let j = 0; j < 21; j++) {
        row.push({
          cell: React.createRef<HTMLTableDataCellElement>(),
          icon: React.createRef<Icon>(),
          weight: 0,
          aspectId: -1
        });
      }

      this._cells.push(row);
    }
  }

  async componentDidMount() {
    this.update(this.props);
  }

  componentWillUnmount() {
    this.props.onChanged('pinned-aspects', []);
  }

  shouldComponentUpdate(newProps: AspectsTableProps, newState: any) {
    if (newProps.data.form.cosmogram !== this.props.data.form.cosmogram) return true;
    this.update(newProps);
    return true; //return false;
  }

  updateCells(props: AspectsTableProps, show: boolean, mode: TWidgetCircleMode) {

    // вычисляем реальный mode, так как в props.data.widgetMode могут быть субрежимы типа 'compatibility1-transit-partner'
    const _mode = extractMode(props.subMode, '-') as CircleMode;
    const map: IMap | undefined = props.data.maps.find(m => m.mode === _mode);

    if (!map) return;


    const isSynastryTransitNatalMode = isCompatibility(_mode) && props.subMode === `${_mode}-transit-natal`;
    const isSynastryTransitPartnerMode = isCompatibility(_mode) && props.subMode === `${_mode}-transit-partner`;
    
    // индикатор карт
    // включен/отключен натал в двойной карте прогностики
    // @ts-ignore
    const indicatorPrognosticsNatal: boolean = (props.data.doubleMapNatal as any)?.[map.mode as PrognosticsMode];
    const { transitMode } = props.mapIndicator?.compatibilityTransits[_mode] || {};
    const { compatibilityReversed } = props.mapIndicator;
    const hasSynastryTransit: boolean = Boolean(props.data.form?.syn_prognostics);

    // const aspects = map.aspects.filter(a => a.obj1 !== ObjectType.Selena && a.obj2 !== ObjectType.Selena)

    let _aspects = map.aspects;

    // если в двойной карте отключен Натал, то скрываем аспекты с минорными домами
    if (!indicatorPrognosticsNatal && ['solars', 'prog_natal', 'transits'].includes(map.mode)) {
      _aspects = _aspects.filter((aspect: IAspect) => {
        const { type, obj1, obj2 } = aspect;
        return (obj1 >= ObjectType.House1 ? axesObjects.includes(obj1) : true)
      })
    }

    // если subMode == совместимость и включены транзиты для этой совместимости и режим транзита = 1
    //, то уберем из аспектов транзитные, что бы показывать их уже в отдельном табе
    if (hasSynastryTransit && transitMode === ECompatibilityTransitMode.MODE_1) {
      
      const { transitPartnerAspects = [], transitNatalAspects = [] } = map || {};
      const transitAspects = (transitPartnerAspects).concat(transitNatalAspects);

      // const housesAspects = transitAspects.filter((aspect: IAspect) => (aspect.obj1 >= ObjectType.House1) || (aspect.obj2 >= ObjectType.House2))
      
      
      if (isCompatibility(props.subMode as CircleMode) && isCompatibility(_mode)) {
        _aspects = _aspects.filter((aspect: IAspect) => {
        
          return !transitAspects.some((tAspect: IAspect) => {
            return (
              tAspect.diff === aspect.diff
              && tAspect.lon1 === aspect.lon1
              && tAspect.lon2 === aspect.lon2
              && tAspect.obj1 === aspect.obj1 
              && tAspect.obj2 === aspect.obj2 
              && tAspect.type === aspect.type
            )
          });
        })
      }

      else if (isSynastryTransitNatalMode) {
        _aspects = (!compatibilityReversed ? map.transitNatalAspects : map?.transitPartnerAspects) || []
      }
    
      else if(isSynastryTransitPartnerMode) {
        _aspects = (!compatibilityReversed ? map.transitPartnerAspects : map?.transitNatalAspects) || []
      }

      
    } 
    // если это совместимость и транзитный режим === 2
    else if (hasSynastryTransit && transitMode === ECompatibilityTransitMode.MODE_2) {
      // _aspects = _aspects.filter((aspect: IAspect) => aspect.type === AspectType.Conjunction)
      // _aspects = [...(map.transitNatalAspects || []), ...(map.transitPartnerAspects || [])]
    }

    // console.log(`AspectTable updateCells for 
    //   mode: ${_mode}, 
    //   subMode: ${props.subMode},
    //   orbiseCorrector: ${(props.astroProfile.maps as any)[_mode].orbiseCorrector},
    //   aspects - `, props.data.maps[0].aspects
    // );

    const asps = new Set<number>();

    _aspects.forEach(asp => {
      const col = getCellIndex(asp.obj1);
      const row = getCellIndex(asp.obj2);
      const w = row!.w + col!.w;
      if (!col || !row) {
        console.log('fail asp', asp);
        return;
      }

      let cell

      if (_mode === 'natal' || _mode === 'syn_natal' || _mode.startsWith('partner')) {
        
        if (asp.obj2 >= ObjectType.House1) {
          cell = this._cells[row!.id][col!.id]

        } else {
          cell = this._cells[col!.id][row!.id]
        }
      } else {
        cell = this._cells[col!.id][row!.id];
      }


      // const isDisabled = astro.getOrbise(map.mode, asp.obj1, asp.type)?.disabled;

      if (!cell || (show && w < cell.weight)) { return }

      (cell.icon?.current)?.setIcon(show ? aspectsIcons[asp.type] : null, `var(--circle-aspects-${asp.type})`);

      cell.aspectId = show ? asp.id : -1;
      cell.weight = show ? w : 0;

      asps.add(asp.id);

      if (props.data.pinnedAspects.includes(asp.id)) {
        cell.cell.current!.classList.toggle('pinned');
      }
    });

  }

  clearCells(props: AspectsTableProps) {
    for (let i = 0; i < 21; i++) {
      for (let j = 0; j < 21; j++) {
        const cell: IAspectsTableCell = this._cells[i][j];
        cell.icon?.current?.setIcon(null, '');
        cell.aspectId = -1;
      }
    }
  }

  update(props: AspectsTableProps) {
    

    // вычисляем реальный mode, так как в props.data.widgetMode могут быть субрежимы типа 'compatibility1-transit-partner'
    const _mode = extractMode(props.subMode, '-') as CircleMode;
    const isPrognosticsMode = ['directions', 'solars', 'prog_natal', 'transits'].includes(_mode);

    const isSynastryTransits = props.data.form?.syn_prognostics;
    const { transitMode } = props.mapIndicator.compatibilityTransits[_mode] ?? {};
    const isSynastryTransitNatalMode = isCompatibility(_mode) && props.subMode === `${_mode}-transit-natal`;
    const isSynastryTransitPartnerMode = isCompatibility(_mode) && props.subMode === `${_mode}-transit-partner`;

    const ref = this._ref.current as HTMLTableElement;

    ref.setAttribute('data-small', isPrognosticsMode.toString());

    ref.setAttribute('data-show-chiron', (_mode == 'transits').toString());

    if (
        isPrognosticsMode 
        || _mode.startsWith('relocation') 
        || ['natal','syn_natal'].includes(_mode) 
        || _mode.startsWith('partner')
        || (isSynastryTransits && (isSynastryTransitNatalMode || isSynastryTransitPartnerMode) && transitMode === ECompatibilityTransitMode.MODE_1)) {
      ref.setAttribute('data-show-h-axis', 'true');
    } else {
      ref.setAttribute('data-show-h-axis', 'false');
    }
    
    ref.setAttribute('data-show-v-axis', isPrognosticsMode.toString());

    if (!(_mode == 'transits' || isPrognosticsMode)) {
      ref.querySelectorAll('tr td:not(.chiron):not(.v-axis)').forEach((item, i) => {
        if ((i + 1) % 14 === 0) { item.classList.add('withoutRightBorder') }
      });
    } else {
      ref.querySelectorAll('tr td:not(.chiron):not(.v-axis)').forEach((item, i) => {
        if ((i + 1) % 14 === 0) { item.classList.remove('withoutRightBorder') }
      });
    }

    // this.updateCells(this.props, false, _mode);

    this.clearCells(props);

    this.updateCells(props, true, _mode);

    this.hideAspect(ref, _mode)

  }

  onClick(ev: any, y: number, x: number) {

    const { aspectId } = this._cells[y][x];

    let pinned: number[] = [];

    if (aspectId !== -1) {
      if (ev.ctrlKey || ev.metaKey) {
        if (this.props.data.pinnedAspects.includes(aspectId)) {
          pinned = this.props.data.pinnedAspects.filter(id => id !== aspectId);
        } else {
          pinned = [...this.props.data.pinnedAspects, aspectId];
        }
      } else {
        pinned = [aspectId];
      }
    }

    this.props.onChanged('pinned-aspects', pinned);
  }

  hideAspect(ref: HTMLTableElement, mode: TWidgetCircleMode) {
    const rowLilith = ref.querySelector('td.lilith.row_header')?.parentElement
    const rowChiron = ref.querySelector('td.chiron.row_header')?.parentElement
    const rowSouthNode = ref.querySelector('td.south-node.row_header')?.parentElement

    if (mode === 'transits' && this.props.activeProfileId === 0) {
      rowLilith?.classList.add('not-show')
      rowChiron?.classList.add('not-show')
      // rowSouthNode?.classList.add('not-show')
    } else {
      rowLilith?.classList.remove('not-show')
      rowChiron?.classList.remove('not-show')
      rowSouthNode?.classList.remove('not-show')
    }
  }

  render() {
    const rows = [];

    const headers: any[] = [<Cell key="corner" className="v-header" ref={this._cells[0][0].cell}><Icon dot /></Cell>];

    const isTransits = this.props.data.aspectsTableMode === 'transits'
    const isActiveProfilePA = this.props.activeProfileId === 0; // Школа ПА
    const isCompatibility = this.props.data.aspectsTableMode.startsWith('compatibility') // добавленна для автотестов в data-td-display
    const hidedModes = ['natal','partner','relocation_natal','compatibility'].some(mode => this.props.data.aspectsTableMode.startsWith(mode)); // добавленна для автотестов в data-td-display
    const prognosticModes = ['solars','directions','prog_natal'].some(mode => this.props.data.aspectsTableMode.startsWith(mode)) // добавленна для автотестов в data-td-display
 

    for (let i = 0; i < 20; i++) {
      headers.push(
        <Cell
          key={`col_header_${i}`}
          className={`v-header ${cellClassName(i, 'v')}`}
          ref={this._cells[0][i + 1].cell}
        >
          {headerIcon(i)}
        </Cell>
      );
    }

    rows.push(<Row key={'col_headers'}>{headers}</Row>);
    const cosmogramNatalmode = this.props.data.form?.cosmogram && this.props.data.modes.includes('natal')
    const quantityOfCells = (this.props.data.form?.cosmogram && this.props.data.modes.includes('transits')) || cosmogramNatalmode ? 14 : 20;
    
    for (let i = 0; i < quantityOfCells; i++) {
      const hClassName = cellClassName(i, 'h');

      const chironOrLilith = i === ObjectType.Chiron  || i === ObjectType.Lilith
      const hideRowMark = ( // добавленна для автотестов в data-tr-display
        (!isTransits && i === ObjectType.Chiron) 
        || (isTransits && isActiveProfilePA && chironOrLilith)
        || (isCompatibility && i >= ObjectType.Chiron)
      )

      const cols: any[] = [
        <Cell
          key={`row_header_${i}`}
          ref={this._cells[i + 1][0].cell}
          className={`${hClassName} row_header`}
        >
          {headerIcon(i)}
        </Cell>
      ];

      for (let j = 0; j < quantityOfCells; j++) {
        cols.push(
          <Cell
            key={`cell_${j}`}
            //onMouseEnter={() => this.onHover(i + 1, j + 1)}
            //onMouseLeave={() => this.onHover(0, 0)}
            onClick={ev => this.onClick(ev, i + 1, j + 1)}
            ref={this._cells[i + 1][j + 1].cell}
            className={`${cellClassName(j, 'v')} ${hClassName}`}
            data-td-display={`${(hidedModes && j >= ObjectType.Chiron) || (prognosticModes && j === ObjectType.Chiron)  ? 'none' : ''}`} 
          >
            <Icon ref={this._cells[i + 1][j + 1].icon} />
          </Cell>
        );
      }
      rows.push(<Row key={`row_${i}`}  data-tr-display={`${hideRowMark ? 'none' : ''}`}>{cols}</Row>);
    }

    return (
      <Container>
        
        <TableWrapper>
          <Table ref={this._ref}>
            <tbody>
              {rows}
            </tbody>
          </Table>
        </TableWrapper>
        
        <OrbiseCorrector {...this.props} />
      
      </Container>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    activeProfileId: state.settings.settings.data.activeProfileId,
    mapIndicator: getWorkspaceIndicator(state),
    astroProfile: getActiveAstroProfile(state),
  };
};

export default connect(
  mapStateToProps,
)(AspectsTable);

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: scroll;
  margin-bottom: 0.875rem;
`

const Table = styled.table`
  border-collapse: collapse;

  box-sizing: border-box;

  &[data-small=true] {
    td {
      width: 1.5em;
      height: 1.5em;
    }
  }

  &[data-show-h-axis=false] {
    td.h-axis {
      display: none;
    }
  }

  &[data-show-v-axis=false] {
    td.v-axis {
      display: none;
    }
  }

  &[data-show-chiron=false] {
    td.chiron {
      display: none;
    }
  }

  .not-show {
    display: none;
  }
`;

const Row = styled.tr`
  :first-child td{
    border-top: 1px solid var(--aspects-table-border);
    border-bottom: 1px solid var(--aspects-table-border-light);
  }

  & > td:first-child{
    border-right: 1px solid var(--aspects-table-border-light);
  }
`;

const Cell = styled.td`
  padding: 0;
  box-sizing: border-box;

  border-right: 1px solid var(--aspects-table-border);
  border-bottom: 1px solid var(--aspects-table-border);


  &.pinned {
    background: var(--aspects-table-background-hover);
    position: relative;

    &:before{
      display: block;
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      transform: translate(-1px, -1px);
      border: 1px solid var(--aspects-table-border-light);
      border-radius: 1px;
    }
  }


  &.current {
    background: var(--aspects-table-background-current);
    position: relative;

    &:before{
      display: block;
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      transform: translate(-2px, -2px);
      border: 2px solid #EF5350;
      border-radius: 4px;
    }
    /* border: 1px solid #EF5350; */
  }

  &.h-line {
    background: var(--aspects-table-background-hover);
    border-bottom-color: var(--aspects-table-border-light);
  }
  &.h-line-prev {
    border-bottom-color: var(--aspects-table-border-light);
  }
  &.h-first {
    position: relative;
    &:after{
      display: block;
      content: '';
      top: 0;
      position: absolute;
      width: 1px;
      height: 100%;
      background-color: var(--aspects-table-border-light);
    }
  }

  &.v-line {
    background: var(--aspects-table-background-hover);
    border-right-color: var(--aspects-table-border-light);
  }
  &.v-line-prev {
    border-right-color: var(--aspects-table-border-light);
  }
  &.v-first {
    position: relative;
    &:after{
      display: block;
      content: '';
      top: 0;
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: var(--aspects-table-border-light);
    }
  }

  &.withoutRightBorder {
    border-right: none;
  }
`;
