import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useSwipeable } from 'react-swipeable';
//import { disablePageScroll, enablePageScroll } from 'scroll-lock';

import { LeftTriangleIcon } from 'src/assets/icons/arrows/index';
import { StarIcon } from 'src/assets/icons/system';
export interface IPopupBottomProps {
  children: any,
  open?: boolean,
  back?: boolean,
  title?: string,
  onClose: () => void,
  overlayColor?: string,
  marginBottom?: number,
  id?: string,
  strong?: boolean,
  modal?: boolean,
  icon?: any
}

const PopupBottom = React.memo(function PopupBottom({
  modal = true,
  ...props
}: IPopupBottomProps){
  const displayRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);

  const overlayColor = props.overlayColor || 'var(--bg-popup-overlay)';
  const marginBottom = props.marginBottom || 0;

  useEffect(() => {
    displayRef.current!.style.visibility = 'hidden';
  }, [])

  useEffect(() => {
    const allOverlays = document.querySelectorAll('.popup-bottom');
    const allZIndex = Array.from(allOverlays).map(item => +getComputedStyle(item).zIndex);
    const maxZIndex = Math.max(...allZIndex);

    overlayRef?.current && (overlayRef.current.style.zIndex = `${maxZIndex + 1}`);
    popupRef?.current && (popupRef.current.style.zIndex = `${maxZIndex + 2}`);

    if(props.open) {
      displayRef.current!.style.visibility = 'initial';
      popupRef.current?.scrollTo(0, 0);
    } else {
			displayRef.current!.style.visibility = 'hidden';
    }

    //return () => enablePageScroll();

  }, [props.open])

  const swipeHandlers = useSwipeable({
    onSwipedDown: () => props.onClose()
  });

	const childrenWithProps = React.Children.map(props.children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child as React.ReactElement<any>, { popupref: popupRef })
    }

    return child;
  })

  return <div ref={displayRef}>
    <Container open={props.open} ref={popupRef} className="popup-bottom" marginBottom={marginBottom} strong={props.strong} id={props.id} >
      <PullLineContainer onClick={props.onClose} id={props.id && `${props.id}__pullline`} {...swipeHandlers}><PullLine /></PullLineContainer>
      <Content>
        {props.title && <TitleContainer>{props.icon && props.icon}{props.title}</TitleContainer>}
        {props.back && <BackIcon onClick={props.onClose} />}
        {childrenWithProps}
      </Content>
    </Container>

    {props.open && modal &&
      <Overlay onClick={props.onClose}
        ref={overlayRef}
        color={overlayColor}
        marginBottom={marginBottom}
        id={props.id && `${props.id}__overlay`} />
    }
  </div>
});

export { PopupBottom };

const Container = styled.div<{open?: boolean, marginBottom: number, strong?: boolean}>`
  display: flex;
  flex-direction: column;

  width: 100%;
	max-height: 100%;
  position: fixed;
  z-index: 5001;
  bottom: 0;
  transform: translateY(${p => p.open ? (p.marginBottom) + 'px' : '100%'});
  left: 0;
  padding: 0 1rem 2rem 1rem;
  overflow: auto;
  background: ${p => p.strong ? 'var(--bg-popup-strong)' : 'var(--bg-popup)'};
  backdrop-filter: ${p => p.strong ? 'none' : 'blur(20px)'};
  border-radius: 0.75rem 0.75rem 0 0;
  transition: transform 0.5s;
  touch-action: pan-y;

	&.block_scroll {
    overflow: hidden !important;
	}

`
const PullLineContainer = styled.div`
  width: 100%;
  padding: 0.75rem 0 1.25rem 0;
`

const PullLine = styled.div`
  width: 2rem;
  height: 0.1875rem;
  margin: 0 auto;

  background-color: var(--input-border);
  border-radius: 1.875rem;
`

const Content = styled.div`
  flex: 1;
`

const Overlay = styled.div<{color: string, marginBottom: number}>`
	position: fixed;
	top: ${p => (p.marginBottom) + 'px'};
	left: 0;
  width: 100vw;
  height: 100%;
  position: fixed;
  z-index: 5000;
	top: 0;
  left: 0;
  background: ${p => p.color};
`

const TitleContainer = styled.div`
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.125rem;

  svg {
    width: 1rem;
    height: 1rem;
    margin-right: 1rem;
  }
`

const BackIcon = styled(LeftTriangleIcon)`
  position: absolute;
  left: 1rem;
  top: 2.45rem;
`
