import React from 'react';
import styled from 'styled-components';

import { PinIcon } from 'src/assets/icons/system/index';
import { MenuIcon } from 'src/assets/icons/system/index';

import { MapDark } from 'src/assets/temp/index';
import { MapLight } from 'src/assets/temp/index';
import { IShortFormData, IFormLabel, Avatar } from 'src/libs';

import { Link } from 'react-router-dom';
import theme from 'src/theme';
import Label from 'src/ui/Label';
import { useTranslation } from 'src/i18n/useTranslation';
import { useSelector } from 'src/store/utils';


export default function GridItemForm({
  item,
  onOpenMenu
}: {
  item: IShortFormData,
  onOpenMenu(f: IShortFormData): void;
}){
  const allLabels: IFormLabel[] = useSelector(state => state.labels.data);
  
  const labels: IFormLabel[] = (item.labels || [])
  .map((labelId) => allLabels.find((label) => Number(label.id) === Number(labelId)))
  .filter((label): label is IFormLabel => Boolean(label));

  const { t } = useTranslation();
  return <Container>
    <Buttons>
      <div>{item.isPinned && <PinIcon />}</div>
      <div><MenuIcon onClick={() => onOpenMenu(item)} /></div>
    </Buttons>
    
    <Image><Link to={`/workspace/${item.id}`} >
      {theme.actual === 'light' ? <MapLight /> : <MapDark />}
    </Link></Image>

    <Content><Link to={`/workspace/${item.id}`} >
      <NameWithIcon>
        <Avatar size={24} color={item.color} bgColor="var(--bg-element-neutral)" />
        <Name>{t(item.name)}</Name>
      </NameWithIcon>
      
      <SmallText>{t((item as any).place)}</SmallText>

      {labels?.length > 0 && <Labels>
        {labels.map(item => <Label key={item.id} color={item.color}>{item.title}</Label>)}
      </Labels>}
    </Link></Content>

    

  </Container>
}

const Container = styled.div`
  display: grid;
  grid-template-rows: min-content min-content 1fr;
  min-height: 14rem;
`

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--text-secondary);
`

const Image = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 6rem;
  
  > svg {
    height: 100%;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const NameWithIcon = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-gap: 0.5rem;
  margin-bottom: 0.5rem;
  align-items: center;
`

const Name = styled.div`
  //display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 0.875rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 0.75rem;
`

const SmallText = styled.div`
  color: var(--text-secondary);
  font-size: 0.75rem;
  line-height: 1.2;
`

const Labels = styled.div`
  margin-top: 0.1rem;

  > div {
    margin-right: 0.4rem;
    margin-top: 0.4rem;
  }
`