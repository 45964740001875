import { IUser, IAuth } from "src/libs";
import dayjs from "dayjs";
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { get } from "lodash";

dayjs.extend(isSameOrBefore);

const PROFILE_LS_NAME = 'mobile_profile_v2.4';


export const getProfile = (): Partial<IUser> & Partial<IAuth>  => {
  const item = localStorage.getItem(PROFILE_LS_NAME);
  return item && JSON.parse(item || '{}');
}

export const setProfile = (data: any) => {
  localStorage.setItem(PROFILE_LS_NAME, JSON.stringify(data));
}

export const unsetProfile = () => {
  localStorage.removeItem('mobile_profile_v2.2'); // 25/03/22
  localStorage.removeItem('mobile_profile_v2.3'); // 25/03/22
  localStorage.removeItem(PROFILE_LS_NAME);
}

export const isTrialAccess = (): boolean => {
  const { permissions } = getProfile()
  return permissions?.isTrial ?? false;
}

export const isBaseTariff = (): boolean => {
  const { permissions } = getProfile()
  return (permissions?.hasBeginnerAccess ?? false) && !(permissions?.isExpired);
}

export const isArchiveTariff = (): boolean => {
  const { permissions } = getProfile()
  return (permissions?.hasArchivedAccess ?? false) && (!permissions?.isExpired)
}

export const isExtendedTariff = (): boolean => {
  const { permissions } = getProfile()
  return (permissions?.hasProfessionalAccess ?? false) && (!permissions?.isExpired)
}

export const isUnlimitedTariff = (): boolean => {
  const { permissions } = getProfile()
  return (permissions?.hasUnlimitedAccess ?? false) && (!permissions?.isExpired)
}

export const isLimitedAccess = (): boolean => {

  return Boolean(
    !isTrialAccess()
    && !isBaseTariff()
    && !isArchiveTariff()
    && !isExtendedTariff()
    && !isUnlimitedTariff()
  );
}