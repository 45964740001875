import api, { IUser } from 'src/api';
import { authURL } from 'src/helpers/api';
import { Dispatch } from 'redux';
import { setProfile, unsetProfile, getProfile as getProfileLS } from 'src/store/localStorage/profile';
import { IActionProfileGetLoading, IActionProfileGetSuccess } from './types';

export const PROFILE_GET_SUCCESS = 'PROFILE_GET_SUCCESS';
export const PROFILE_GET_LOADING = 'PROFILE_GET_LOADING';

export const signIn = (token: string) => { // DEEPRECATED
  return (dispatch: Dispatch) => {
    api.signIn(token)
        .then(data => {
          setProfile(data);
          api.token = data.token;
          // dispatch(redirectToHome());
        })
        .catch(err => {
          console.log(`Ошибка: ${err}`);
          // alert(t("chronos.mobile.authorisationError"))
          // unsetProfile();
          // dispatch(signOutSuccess());
        });
  }
};

export const signOut = (error?: Error) => {
    const lsUser = getProfileLS();
    const sessionId = lsUser?.sessionId;
    const sessionIdNumber = sessionId !== undefined ? Number(sessionId) : 0;
    return (dispatch: Dispatch) => {
        fetch(`${authURL}/logout`, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            credentials: 'include',
            body: JSON.stringify({ sessionId: sessionIdNumber })
        })
            .then(response => response.text())
            .then(_ => {
                // unsetProfile();
                dispatch(signOutSuccess());
            })

      error && console.log('ERROR: ', error);
    };
}


export const getProfile = () => {
    return (dispatch: Dispatch) => {
        dispatch(getProfileLoading());
        api.profile()
            .then((data: IUser) => { dispatch(getProfileSuccess(data)) })
            .catch(_ => { signOut(_)(dispatch); });
    };
}

const redirectToHome = () => {
  return {
    type: 'REDIRECT',
    payload: {
        method: 'push',
        url: `/${window.location.search}`,
    }
}
}

const signOutSuccess = () => {
  return redirectToHome();
}

const getProfileLoading = (): IActionProfileGetLoading => {
  return {
    type: PROFILE_GET_LOADING
  }
}

const getProfileSuccess = (data: IUser): IActionProfileGetSuccess => {
  return {
    type: PROFILE_GET_SUCCESS,
    payload: data
  }
}
