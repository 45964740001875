import { 
  Avatar, 
  Button, 
  show, 
  IAstroSettings, 
  IBaseData, 
  getGMT, 
  IFormData, 
  IPlaceEx, 
  nowISOString, 
  defaultHousesSystem, 
  ISynastryPartnerData, 
  HousesSystem, 
  makeForm, 
  IShortFormData
} from 'src/libs';

import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import { cloneDeep } from 'lodash';
import React, { SyntheticEvent, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TMode, TSaveFormMode } from 'src/app/pages/workspace';
import { HorarIcon, NatalIcon, PrognosticsIcon, SynastryIcon } from 'src/assets/icons/maps/index';
import { MinusIcon, PersonIcon, PlusIcon, PlusInCircleIcon } from 'src/assets/icons/system/index';
import { getForm } from 'src/helpers/api';
import { checkGenChanged } from 'src/helpers/astro';
import { dateFromIsoString, timeFromIsoString, toISOStringFromDateAndTime } from 'src/helpers/dates';
import { useTranslation } from 'src/i18n/useTranslation';
import { getSettings, getSettingsAstro, updateAstroSettings, updateSettings } from 'src/store/actions/settings';
import { getActiveAstroProfile } from 'src/store/reducers/settings/selectors';
import { NotificationContext } from 'src/store/context/notifications';
import { isLimitedAccess, isBaseTariff, isTrialAccess } from 'src/store/localStorage/profile';
import { addForm, deleteForm } from 'src/store/reducers/forms/actions';
import { allForms } from 'src/store/reducers/forms/selectors';
import { allSettings, allSettingsAstro } from 'src/store/reducers/settings/selectors';
import { useSelector } from 'src/store/utils';
import ConfirmPopupButton from 'src/ui/Popups/ConfirmPopupBottom';
import SwipeTabs, { ISwipeTab, TabWithIcons } from 'src/ui/SwipeTabs';
import styled, { css } from 'styled-components';
import MainGroup from './MainGroup';
import SavePartner from './SavePartner';
import { ExpandedSection } from './ExpandedSection';

import api from 'src/api';

dayjs.extend(utcPlugin);
const MAX_PARTNERS_AMOUNT = 5;

export interface IFormError {
  node: TMode | null;
  field: string;
  text: string;
}

function defaultBaseData(): IBaseData {
  return {
    dt: nowISOString(),
    gmt: 0,
    place: {
      name: '',
      lat: 0,
      lon: 0
    }
  };
}

export function activeHousesSystem(activeAstroProfile: IAstroSettings) {
  return activeAstroProfile?.housesSystem || defaultHousesSystem;
}

const getInitialForm = (activeAstroProfile: IAstroSettings): IFormData => {
  const makedForm = makeForm();

  return {
    ...makedForm,
    name: 'новая карта',
    natal: defaultBaseData(),
    housesSystem: activeHousesSystem(activeAstroProfile),
    
    access: {
      ...makedForm.access,
      isPersonal: true,
    },
    
    horar: undefined,
    synastry: undefined,
    prognostics: undefined,
    syn_prognostics: null,
    partners: []
  }
}

export interface IPartnerDataWithAdditionalParams extends ISynastryPartnerData {
  partnerFormId?: number;
  invalidDateTime?: boolean;
	opened?: boolean
}

export default React.memo(function EditForm({
  formId,
  form = null,
  onClose,
  open,
  mode = 'natal',
  onAddNode,
  onRemoveNode,
  onUpdateForm,
  saveFormMode,
	popupRef,
}: {
  formId: number;
  form?: IFormData | null;
  onClose: () => void;
  open?: boolean;
  mode?: TMode;
  onAddNode: (nodeData: IBaseData, node: TMode) => void;
  onRemoveNode:(node: TMode) => void;
  saveFormMode: TSaveFormMode;
  onUpdateForm?(newForm: any, mode: TMode): void;
	popupRef?: React.RefObject<HTMLDivElement>;
}){

  const DEFAULT_DT = '2000-01-01T12:00:00.000Z';

  // const { data: profiles } = useSelector(allSettingsAstro);
  const { data: settings } = useSelector(allSettings);
  const { data: forms} = useSelector(allForms);

  const activeAstroProfile = useSelector(getActiveAstroProfile);

  const [localForm, setLocalForm] = useState<IFormData>(form ?? {...getInitialForm(activeAstroProfile), name: ''});
  const [previousForm, setPreviousForm] = useState<IFormData>({...getInitialForm(activeAstroProfile), name: ''});

  const [saveLoading, setSaveLoading] = useState(false);
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);

  const [natalDate, setNatalDate] = useState(DEFAULT_DT);
  const [natalTime, setNatalTime] = useState(DEFAULT_DT);

  const [prognosticsDate, setPrognosticsDate] = useState(DEFAULT_DT);
  const [prognosticsTime, setPrognosticsTime] = useState(DEFAULT_DT);

  const [synPrognosticsDate, setSynPrognosticsDate] = useState(DEFAULT_DT);
  const [synPrognosticsTime, setSynPrognosticsTime] = useState(DEFAULT_DT);

  const [horarDate, setHorarDate] = useState(DEFAULT_DT);
  const [horarTime, setHorarTime] = useState(DEFAULT_DT);


  const [activeMode, setActiveMode] = useState<TMode>(mode);

  const [disabled, setDisabled] = useState<boolean>(false);

  const [errors, setErrors] = useState<IFormError[]>([]);
  

  const [showAspects, setShowAspects] = React.useState<boolean>(settings.ui.instruments.cardSettingsForms.horar.showAspects);
  const [showHigherPlanets, setShowHigherPlanets] = React.useState<boolean>(activeAstroProfile.showHigherPlanets);
  const [fixedStars, setFixedStars] = React.useState<{ [key: string]: any }>({ ...activeAstroProfile.fixedStars });

  const isNewMap = formId === -1;
  const isTempMap = localForm.isTemporary;
  const isPersonalMap = localForm.access.isPersonal;
  const showNameInput = !isTempMap || ((isTempMap || !isPersonalMap) && saveFormMode === 'add')

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const context = useContext(NotificationContext);
  const { t } = useTranslation();

  useEffect(() => {
    // const _mode = mode === 'syn_prognostics' ? 'prognostics' : mode;
    setActiveMode(mode);
  }, [mode]);

  // 
  useEffect(() => {

    if (!open) return;

    const isNewForm = formId === -1 && localForm.id === -1;

    const init = async () => {

      setFixedStars({ ...activeAstroProfile.fixedStars });

      try {

        let _form = localForm;


        if (isTempMap) {
          // _form = await getForm(formId);
          _form.name = t("chronos.app.tmpMap");
        }

        if (_form) {

          if (isNewForm) {
            _form.partners = [];
            _form.synastry = undefined;
            _form.prognostics = undefined;
            _form.syn_prognostics = null;
          }

          setNatalDate(_form.natal.dt);
          _form.prognostics && setPrognosticsDate(_form.prognostics.dt);
          _form.syn_prognostics && setSynPrognosticsDate(_form.syn_prognostics.dt);
          _form.horar && setHorarDate(_form.horar.dt);

          setNatalTime(_form.natal.dt);
          _form.prognostics && setPrognosticsTime(_form.prognostics.dt);
          _form.syn_prognostics && setSynPrognosticsTime(_form.syn_prognostics.dt);
          _form.horar && setHorarTime(_form.horar.dt);

          setLocalForm({
            ..._form
          });
          setPreviousForm({
            ..._form
          });
          
          setDisabled(false);

          if (mode) {
            // const _mode = mode === 'syn_prognostics' ? 'prognostics' : mode;
            setActiveMode(mode);
          }
        }

      } catch (err) {
        console.log('EditForm init err -', err);
      }

    }


    init();
  }, [formId, open, saveFormMode]);

  const { name = '', originalName, lat, lon, originalLat, originalLon, showUserCoordinates } = localForm.natal.place as IPlaceEx;
  const placeIsBad = name && name !== originalName && (lat === 0 && lon === 0);

  const nodeDt = React.useMemo(() => {
    let nodeDate: string = ''
    let nodeTime: string = '';
    switch (activeMode) {
      case 'natal': {
        nodeDate = natalDate;
        nodeTime = natalTime;
        break;
      }
      case 'prognostics': {
        nodeDate = prognosticsDate;
        nodeTime = prognosticsTime;
        break;
      }

      case 'syn_prognostics': {
        nodeDate = synPrognosticsDate;
        nodeTime = synPrognosticsTime;
        break;
      }

      case 'horar': {
        nodeDate = horarDate;
        nodeTime = horarTime;
        break;
      }
    }
    const result = nodeDate && nodeTime ? toISOStringFromDateAndTime(dateFromIsoString(nodeDate), timeFromIsoString(nodeTime)) : '';
    return result;
  }, [activeMode, natalDate, natalTime, prognosticsDate, prognosticsTime, synPrognosticsDate, synPrognosticsTime, horarDate, horarTime]);

  useEffect(() => {

    if (!nodeDt || nodeDt === DEFAULT_DT) return;

    setLocalForm(form => {
      // если отсутствует, то выставляем дефолтные данные
      const nodeData = (form as any)[activeMode];
      if (nodeData) {
        const _gmt = nodeData.place?.lat && nodeData.place?.lon ? getGMT(nodeDt, nodeData.place.lat, nodeData.place.lon) : nodeData.gmt;
        return {
          ...form,
          [activeMode]: {
            ...nodeData,
            gmt: _gmt
          }
        }
      } else {
        return {
          ...form
        }
      }
    });

  }, [nodeDt, activeMode]);

  const [partnerOpened, setPartnerOpened] = useState<boolean[]>(Array.from({ length: MAX_PARTNERS_AMOUNT }, () => false)); // генерим массив и заполняем [false, ...]

	const switchPartnerOpened = (currentIndex: number) => {
		setPartnerOpened(partnerOpened => partnerOpened.map((item, index) => index === currentIndex ? !item : false ))
	}

  const partnerFormSelectOptions = useMemo(() => {
    const opts = forms
    ?.filter((form: IShortFormData) => (form.id !== localForm.id) && !form.isTemporary)
    .map((form: IShortFormData) => ({
      label: form.name,
      value: +form.id,
      icon: <PersonIcon fill={form.color} />
    }))
    .sort((f1: {label: string; value: number}, f2: {label: string; value: number}) => {
      if(f1.label === f2.label) {
        return 0;
      }
      return f1.label > f2.label ? 1 : -1;
    }) || [];
    return [{label: t('chronos.app.components.editForm.newMap'), value: 0, icon: <PlusIcon />}].concat(opts);
  }, [forms]);

  const addNode = (node: TMode) => {
    setActiveMode(node);

    setLocalForm(form => {
      const nodeData = {
        ...defaultBaseData(),
        place: form.natal.place
      }

      return {
        ...form,
        [node]: {
          ...nodeData,
          gmt: nodeData.place?.lat && nodeData.place?.lon
            ? getGMT(nodeData.dt, nodeData.place.lat, nodeData.place.lon)
            : nodeData.gmt
        }
      }
    })
  }

  const onDeleteNode = (node: TMode) => {
    setLocalForm(form => {
      const partners = node === 'synastry' ? [] : form.partners;
      return {
        ...form,
        partners: partners,
        [node]: null
      }
    });
  };


  const addNewPartner = () => {

    setLocalForm(form => {

      // если синастрии нет
      const nodeData = form['synastry'] ?? {
        ...defaultBaseData(),
        place: form.natal.place
      }

      const partners = [
        ...form.partners || [],
        {
          name: '',
          dt: DEFAULT_DT,
          gmt: getGMT(DEFAULT_DT, form.natal.place.lat, form.natal.place.lon),
          place: form.natal.place,
          partnerFormId: undefined
        }
      ];
      switchPartnerOpened(partners.length - 1);
      return {
        ...form,
        ['synastry']: {
          ...nodeData,
          gmt: getGMT(nodeData.dt, nodeData.place.lat, nodeData.place.lon)
        },
        partners: partners,
      }
    });

  }

  const removePartner = (index: number) => {
    setLocalForm(form => {
      form.partners?.splice(index, 1);
      return {
        ...form,
      }
    });
  }

  const onChange = React.useCallback((node: TMode | number, key: string) => async (value: any) => {
    // console.log(`*** --- onChange node fields - node: ${node}  key:${key}  value: ${value}`)
    if (typeof node === 'number') {
      const targetPartner = localForm.partners?.[node];
      
      if(targetPartner) {
        let newData = targetPartner;

        const targetForm = key === 'partnerFormId'
          ? await api.form(value)
          : null;
        

        if(targetForm) {
          // FIXME: !!!
          newData = {
            name: targetForm.name,
            //@ts-ignore
            dt: targetForm.natal.dt,
            place: targetForm.natal.place,
            //@ts-ignore
            gender: targetForm.natal.gender,
            //@ts-ignore
            gmt: targetForm.natal.dateTime?.gmt || 0
          }
        }

        if(key === 'date' && targetPartner.place) {
          newData.gmt = getGMT(value, targetPartner.place.lat, targetPartner.place.lon);
        }

        if(key === 'place' && targetPartner.dt) {
          newData.gmt = getGMT(targetPartner.dt, value.lat, value.lon);
        }

        const objKey = key === 'date' || key === 'time' ? 'dt' : key;
        const objVal = key === 'date' || key === 'time'
          ? toISOStringFromDateAndTime(
              dateFromIsoString(key === 'date' ? value : targetPartner.dt),
              timeFromIsoString(key === 'time' ? value : targetPartner.dt),
            )
          : value;

        setLocalForm(form => {
          const partners = form.partners || [];
          partners[node] = {
            ...newData,
            [objKey]: objVal
          };
          return {
          ...form,
            partners: partners,
          }
        });
      }

    } else {
      
      let newData = {};
      const target = localForm[node];
      
      if(key === 'date' && target?.place?.lat && target.place.lon) {
        newData = {
          gmt: getGMT(value, target.place.lat, target.place.lon),
        };
      }

      if (key === 'place' && value.lat && value.lon && nodeDt) {
        newData = {
          gmt: getGMT(nodeDt, value.lat, value.lon),
        };
      }

      // debugger

      const objKey = key === 'date' || key === 'time' ? 'dt' : key;
      const objVal = key === 'date' || key === 'time'
        ? toISOStringFromDateAndTime(
            dateFromIsoString(key === 'date' ? value : target?.dt),
            timeFromIsoString(key === 'time' ? value : target?.dt),
          )
        : value;

      setLocalForm(form => {
        return {
          ...form,
          [node]: {
            ...target,
            [objKey]: objVal,
            ...newData,
          }
        }
      });
    }
  }, [localForm, nodeDt]);

  const onChangeCosmogram = (cosmogram: boolean) => {
    setLocalForm(form => ({
      ...form,
      cosmogram
    }));
  };

  const onChangeNameHandler = (e: SyntheticEvent) => {
    const name = (e.target as HTMLInputElement).value;
    setLocalForm(form => ({
      ...form,
      name
    }));
  }
    
  const onSubmitForm = React.useCallback((isConfirm = false) => {
    if(saveLoading) return;
    setSaveLoading(true);

    const errors: IFormError[] = [];

    if (localForm.name.trim().length === 0) {
      errors.push({
        node: null,
        field: 'name',
        text: t("chronos.app.titleInput")
      });
    }

    const checkNode = (node: TMode) => {
      if (!node) {
        errors.push({
          node,
          field: 'node',
          text: t("отсутствует")
        });
        return;
      };

      const data = localForm[node] as IBaseData;

      if ((!data.place || data.place?.name.trim().length === 0) && !localForm.cosmogram) {
        errors.push({
          node,
          field: 'place',
          text: t("chronos.app.noLocation")
        });
      }
    };

    checkNode('natal');
    localForm.prognostics && checkNode('prognostics');
    localForm.syn_prognostics && checkNode('syn_prognostics');
    localForm.horar && checkNode('horar');

    localForm.natal.dt = toISOStringFromDateAndTime(dateFromIsoString(natalDate), timeFromIsoString(natalTime));
    localForm.prognostics && prognosticsDate && prognosticsTime && (localForm.prognostics.dt = toISOStringFromDateAndTime(dateFromIsoString(prognosticsDate), timeFromIsoString(prognosticsTime)));
    localForm.syn_prognostics && synPrognosticsDate && synPrognosticsTime && (localForm.syn_prognostics.dt = toISOStringFromDateAndTime(dateFromIsoString(synPrognosticsDate), timeFromIsoString(synPrognosticsTime)));
    localForm.horar && horarDate && horarTime && (localForm.horar.dt = toISOStringFromDateAndTime(dateFromIsoString(horarDate), timeFromIsoString(horarTime)));

    if (localForm.horar && (localForm.horar.question || '').trim().length === 0) {
      errors.push({
        node: 'horar',
        field: 'question',
        text: t("chronos.app.questionInput")
      });
    }

    localForm.partners?.forEach((partnerData, index) => {
      const formName = t(`astro.partner${index + 1}`);
      partnerData.name = partnerData.name?.trim();
      const partnerPlace = partnerData.place as IPlaceEx
      
      if(!partnerData.name) {
        errors.push({
          node: 'synastry',
          field: 'name',
          text: `${formName}: ${t('chronos.app.components.editForm.noName')}`,
        });
      }

      if ((partnerData?.place?.name?.trim().length === 0) && !localForm.cosmogram) {
        errors.push({
          node: 'synastry',
          field: 'place',
          text: `${formName}: ${t('chronos.app.noLocation')}`,
        });
      } else if (
        (partnerPlace?.originalName !== null && (partnerPlace?.name.trim() !== partnerPlace?.originalName) && partnerPlace?.id) ||
        (!partnerPlace?.originalLat && !partnerPlace?.originalLon && partnerPlace?.originalName && partnerPlace?.name && (partnerPlace?.originalName !== partnerPlace?.name) && partnerPlace?.id)
        // && !form.cosmogram
      ) {
        errors.push({
          node: 'synastry',
          field: 'place',
          text: `${formName}: ${t('chronos.app.choosePlaceFromList')}`,
        });
      }
      // отсутствие place.id, говорит о том, что координаты и название места менялись пользователем
      if (
        (!partnerPlace?.id && (!partnerPlace?.lat || !partnerPlace?.lon)) ||
        (!partnerPlace?.id && !partnerPlace?.originalLat && !partnerPlace?.originalLon && !partnerPlace?.originalName && partnerPlace?.name)
      ) {
        errors.push({
          node: 'synastry',
          field: 'place',
          text: `${formName}: ${t('chronos.app.noCoordinates')}`,
        });
      }
    });

    setErrors(errors);

    if (errors.length) {
      setSaveLoading(false);
      return;
    }

    const _settings = cloneDeep(settings);
    _settings.ui.instruments.cardSettingsForms.horar.showAspects = showAspects;
    const _activeAstroProfile = cloneDeep(activeAstroProfile);
    _activeAstroProfile.showHigherPlanets = showHigherPlanets;

    _activeAstroProfile.fixedStars = {
      ...fixedStars,
      [activeMode]: {
        list: fixedStars?.[activeMode]?.list || [],
        showWithObjects: fixedStars?.[activeMode]?.showWithObjects ?? true
      }
    }


    dispatch(updateAstroSettings(_activeAstroProfile, (data) => {
      _settings.activeProfileId = data.id;
        dispatch(updateSettings(_settings, (updatedSettings) => {
          
      }));
    }));

    if (
      !isTempMap && isPersonalMap &&
      checkGenChanged(localForm.gen, localForm.natal.dt, previousForm.natal.dt, localForm.natal.place, previousForm.natal.place) &&
      !isConfirm
    ) {
      setOpenConfirmPopup(true);
      setSaveLoading(false);
    } else {
      localForm.housesSystem = activeHousesSystem(activeAstroProfile);

      if ((isNewMap || isTempMap || !isPersonalMap) && saveFormMode === 'add') {
        const newForm = {
          ...makeForm({
            name: localForm.name,
            housesSystem: activeHousesSystem(activeAstroProfile),
          }),
          natal: localForm.natal,
          prognostics: localForm.prognostics,
          horar: localForm.horar,
          synastry: localForm.synastry,
					partners: localForm.partners,
          settings: localForm.settings
        };

        dispatch(addForm(newForm, (data: any) => {
          
          onClose();
          context?.add(t("chronos.mobile.components.editForm.mapAdded"));
          
          setTimeout(() => {
            isTempMap && resetTempMap()
            queueMicrotask(() => navigate(`/workspace/${data.id}`));
            setSaveLoading(false);
          }, 1250);

        }));

      } else {
          onClose();
          setTimeout(() => {
            onUpdateForm?.(localForm, mode || 'natal');
            setOpenConfirmPopup(false);
            setSaveLoading(false);
          }, 200);
      }
    }
  }, [fixedStars, localForm, activeMode, saveFormMode, showAspects]);

	const resetTempMap = () => {
		dispatch(deleteForm(-1))
	}

  const NatalGroup = <MainGroup
    type={'natal'}
    place={localForm.natal.place}
    date={natalDate}
    time={natalTime}
    gmt={localForm.natal.gmt || 0}
    setPlace={onChange('natal', 'place')}
    setDate={setNatalDate}
    setGMT={onChange('natal', 'gmt')}
    setTime={setNatalTime}
    fixedStars={fixedStars}
    setFixedStars={setFixedStars}
    cosmogram={localForm.cosmogram}
    setCosmogram={onChangeCosmogram}
    // FIXME: !!!
    // @ts-ignore
    gender={localForm.natal.gender}
    setGender={onChange('natal', 'gender')}
    disabled={!localForm.natal || disabled}
    mode={activeMode}
    errors={errors}
		popupRef={popupRef}
    form={localForm}
  />

  const SynastryGroup = <SynastryWrapper>
    {
      localForm.partners?.length
        ? localForm.partners?.map((partner:IPartnerDataWithAdditionalParams, index, arr) => partner && (
          <ExpandedSection
            key={`syn_${index}`}
            title={t(`astro.partner${index + 1}`)}
            leftChevron={true}
            removable={arr.length > 1}
            onDelete={() => removePartner(index)}
            isOpened={partnerOpened[index]}
            switch={() => switchPartnerOpened(index)}
          >
            <SynastryForm>
              <MainGroup
                type={'synastry'}
                place={partner?.place || null}
                date={partner.dt}
                time={partner.dt}
                gmt={partner?.gmt || 0}
                setPlace={onChange(index, 'place')}
                setDate={onChange(index, 'date')}
                mode={activeMode}
                setGMT={onChange(index, 'gmt')}
                setTime={onChange(index, 'time')}
                cosmogram={localForm.cosmogram}
                setCosmogram={onChangeCosmogram}
                gender={partner?.gender}
                fixedStars={fixedStars}
                setFixedStars={setFixedStars}
                setGender={onChange(index, 'gender')}
                disabled={!partner || disabled}
                errors={errors}
                partnerFormOptions={partnerFormSelectOptions}
                selectedFormId={partner.partnerFormId}
                setSelectedFormId={onChange(index, 'partnerFormId')}
                name={partner.name}
                setName={onChange(index, 'name')}
                form={localForm}
              />
              <SavePartner
                partner={partner}
                cosmogram={localForm.cosmogram}
                mode={`partner${index + 1}`}
                activeAstroProfile={activeAstroProfile}
              />
            </SynastryForm>
          </ExpandedSection>
        ))
        : null
    }
    {
      !saveLoading && localForm.partners?.length! < MAX_PARTNERS_AMOUNT &&
      <AddPersonButton color='transparent' onClick={addNewPartner}>
        <PlusIcon />
        {t('chronos.app.components.editForm.addPartner')}
      </AddPersonButton>
    }
  </SynastryWrapper>


  const PrognosticsGroup = <MainGroup
    type={'prognostics'}
    place={localForm.prognostics?.place || null}
    date={prognosticsDate}
    time={prognosticsTime}
    gmt={localForm.prognostics?.gmt || 0}
    natal={localForm.natal}
    mode={activeMode}
    setPlace={onChange('prognostics', 'place')}
    setDate={setPrognosticsDate}
    setGMT={onChange('prognostics', 'gmt')}
    fixedStars={fixedStars}
    setFixedStars={setFixedStars}
    setTime={setPrognosticsTime}
    cosmogram={localForm.cosmogram}
    setCosmogram={onChangeCosmogram}
    gender={undefined}
    setGender={onChange('prognostics', 'gender')}
    disabled={!localForm.prognostics || disabled}
    errors={errors}
    popupRef={popupRef}
    form={localForm}
  />;

  const synPrognosticsGroup = <MainGroup
    type={'syn_prognostics'}
    place={localForm.syn_prognostics?.place || null}
    date={synPrognosticsDate}
    time={synPrognosticsTime}
    gmt={localForm.syn_prognostics?.gmt || 0}
    natal={localForm.natal}
    mode={activeMode}
    setPlace={onChange('syn_prognostics', 'place')}
    setDate={setSynPrognosticsDate}
    setGMT={onChange('syn_prognostics', 'gmt')}
    fixedStars={fixedStars}
    setFixedStars={setFixedStars}
    setTime={setSynPrognosticsTime}
    cosmogram={localForm.cosmogram}
    setCosmogram={onChangeCosmogram}
    gender={undefined}
    setGender={onChange('syn_prognostics', 'gender')}
    disabled={!localForm.syn_prognostics || disabled}
    errors={errors}
    popupRef={popupRef}
    form={localForm}
  />;

  const HorarGroup = <MainGroup
    type={'horar'}
    place={localForm.horar?.place || null}
    date={horarDate}
    time={horarTime}
    gmt={localForm.horar?.gmt || 0}
    mode={activeMode}
    question={localForm.horar?.question}
    setPlace={onChange('horar', 'place')}
    setDate={setHorarDate}
    setGMT={onChange('horar', 'gmt')}
    setTime={setHorarTime}
    fixedStars={fixedStars}
    setFixedStars={setFixedStars}
    cosmogram={localForm.cosmogram}
    setCosmogram={onChangeCosmogram}
    showAspects={showAspects}
    setShowAspects={setShowAspects}
    setHigherPlanets={setShowHigherPlanets}
    gender={undefined}
    setGender={onChange('horar', 'gender')}
    setQuestion={onChange('horar', 'question')}
    disabled={!localForm.horar || disabled}
    errors={errors}
    popupRef={popupRef}
    form={localForm}
  />;

  
  const emptyNodeContent = (node: TMode) => {
    return <EmptyForm className='empty-data' onClick={(evt) => evt.preventDefault()}>
      <span>Нажмите <span><PlusInCircleIcon /></span> чтобы заполнить данные</span>
    </EmptyForm>
  }

  const synastryTab = useMemo(() => {
    return <TabWithIcons>
      <SynastryIcon />
      {t("astro.synastry")}

      {localForm.partners?.length
        ? <MinusIcon onClick={() => onDeleteNode('synastry')} />
        : <PlusInCircleIcon onClick={() => { addNewPartner(); setActiveMode('synastry'); }} />
      }
    </TabWithIcons>
  }, [localForm])

  const prognosticsTab = React.useMemo(() => {
    return <TabWithIcons><PrognosticsIcon />{t("astro.prognostics")}
      {localForm.prognostics && <MinusIcon onClick={() => onDeleteNode('prognostics')} />}
      {!localForm.prognostics && <PlusInCircleIcon onClick={() => addNode('prognostics')} />}
    </TabWithIcons>
  }, [localForm])

  const synPrognosticsTab = React.useMemo(() => {
    return <TabWithIcons><PrognosticsIcon />{t("astro.prognostics")}
      {localForm.syn_prognostics && <MinusIcon onClick={() => onDeleteNode('syn_prognostics')} />}
      {!localForm.syn_prognostics && <PlusInCircleIcon onClick={() => addNode('syn_prognostics')} />}
    </TabWithIcons>
  }, [localForm])

  const horarTab = React.useMemo(() => {
    return <TabWithIcons><HorarIcon />{t("astro.horar")}
      {localForm.horar && <MinusIcon onClick={() => onDeleteNode('horar')} />}
      {!localForm.horar && <PlusInCircleIcon onClick={() => addNode('horar')} />}
    </TabWithIcons>
  }, [localForm])

  const tabs: ISwipeTab[] = (() => {
    if (isLimitedAccess()) {
      return [
        { key: 'natal', title: <TabWithIcons><NatalIcon />{t("astro.natal")}</TabWithIcons>, content: NatalGroup },
      ]
    } else if(isBaseTariff() || isTrialAccess()) {
      return [
        { key: 'natal', title: <TabWithIcons><NatalIcon />{t("astro.natal")}</TabWithIcons>, content: NatalGroup },
        { key: 'synastry', title: synastryTab, content: SynastryGroup },
        { key: 'prognostics',
          title: (activeMode === 'syn_prognostics' ? synPrognosticsTab : prognosticsTab), 
          content: (activeMode === 'prognostics' && localForm.prognostics) 
            ? PrognosticsGroup 
            : (activeMode === 'syn_prognostics' && localForm.syn_prognostics)
              ? synPrognosticsGroup
              : emptyNodeContent(activeMode === 'syn_prognostics' ? 'syn_prognostics' : 'prognostics') 
        },
      ]
    } else {
      return [
        { key: 'natal', title: <TabWithIcons><NatalIcon />{t("astro.natal")}</TabWithIcons>, content: NatalGroup },
        { key: 'synastry', title: synastryTab, content: SynastryGroup },
        { key: 'prognostics', 
          title: (activeMode === 'syn_prognostics' ? synPrognosticsTab : prognosticsTab), 
          content: (activeMode === 'prognostics' && localForm.prognostics) 
          ? PrognosticsGroup 
          : (activeMode === 'syn_prognostics' && localForm.syn_prognostics)
            ? synPrognosticsGroup
            : emptyNodeContent(mode === 'syn_prognostics' ? 'syn_prognostics' : 'prognostics') 
        },
        { key: 'horar', title: horarTab, content: localForm.horar ? HorarGroup : emptyNodeContent('horar') },
      ]
    }
  })() //, React.useMemo( [localForm, partnerOpened, activeMode, showAspects]);


  return (
    <>
      <Header>
        <AvatarContainer>
          <Avatar size={80} color={localForm.color} bgColor="var(--bg-400)"></Avatar>
          {/*<PhotoContainer><PhotoIcon/></PhotoContainer>*/}
        </AvatarContainer>
        {
          showNameInput && 
            <NameInput
              placeholder={t("chronos.app.titleInput")}
              value={localForm.name}
              onChange={onChangeNameHandler}
              error={errors.findIndex(item => item.field === 'name') !== -1}
            />
        }
      </Header>

      <Content>
        <SwipeTabs
          items={tabs}
          active={activeMode}
          open={Boolean(open)}
          recalculateCondition={true}
          onChangeTab={(tabMode) => setActiveMode((tabMode === 'prognostics' && mode === 'syn_prognostics') ? 'syn_prognostics' : tabMode)}
					openedContent={partnerOpened}
        ></SwipeTabs>
      </Content>

      <CustomButton
        disabled={placeIsBad === true}
        onClick={() => onSubmitForm(false)}
        color={errors.length ? 'var(--color-red)' : 'var(--color-blue)'}
      >
        {t("chronos.mobile.components.editForm.saveChanges")}
      </CustomButton>

      <Errors>
        {errors.map(e => <li key={e.text}>{t(e.text)}</li>)}
      </Errors>

      <ConfirmPopupButton
        open={openConfirmPopup}
        onClose={() => setOpenConfirmPopup(false)}
        title={t("base.resetFormations")}
        onConfirm={() => onSubmitForm(true)}
        confirmText={t("base.confirm")}
        strong={true}
      >
        {t("base.resetFormationsInfo")}
      </ConfirmPopupButton>
    </>
  );
});

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
`;

const AvatarContainer = styled.div`
  position: relative;
  margin: 0 auto;
`;

const NameInput = styled.input<{error: boolean}>`
  width: 100%;
  margin-top: 1rem;
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: var(--text-primary);
  text-align: center;

  ${p => p.error && css`
    border-bottom: 1px solid var(--color-red);
  `}
`;

const CustomButton = styled(Button)`
  width: 100%;
	margin-top: 1.5rem;
`;

const Content = styled.div`
	height: 100%;
`;

const AddPersonButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  max-width: 100%;

  & > svg {
    height: 1.125rem;
    margin-right: 0.5rem;
  }
  & > span {
    line-height: 1;
  }
`;

export const Errors = styled.ul`
  list-style-type: none;
  margin-bottom: 1.25rem;
  padding: 0;
  color: var(--colors-red);
  font-size: 0.9em;
`;

const SynastryWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 1.5rem;
`;

const SynastryForm = styled.div`
  position: relative;
  z-index: 1;
  margin-bottom: 1rem;
`;

const EmptyForm = styled.section`
  display: flex;
  align-items: center;
  margin: 1rem 0 1rem;
  max-width: 100%;
  line-height: 1;

  & > span {
    display: inline-flex;
    align-items: center;

    & span {
      font-size: 1.7em;
      margin-inline: 0.5rem;
    }
  }

`;
